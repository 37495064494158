import React, { useEffect, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import { useDeviceSize } from "../context/DeviceSizeContext";
import { usePrefences } from "../context/PrefencesContext";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../components/Common/Alert";
import Requests from "../helpers/Requests";
import {
  ChevronDownIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  LinkIcon,
} from "@heroicons/react/24/solid";
import LoadingSpinner from "../components/Common/Loading/LoadingSpinner";
import { classNames } from "../helpers/Helpers";

function PaymentTransactionHistory() {
  const prefences = usePrefences();
  const ConfigProvider = useConfig();
  const navigate = useNavigate();
  useEffect(() => {
    if (ConfigProvider.config.user === null) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);
  const DeviceSizeProvider = useDeviceSize();
  const deviceType = DeviceSizeProvider.deviceType;
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);

  const [formData, setFormData] = useState({
    selectedTab: "3",
    page: 1,
    offset: 30,
  });
  const [data, setData] = useState([]);
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      Requests.postData("/user/getPaymentTransactions", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            window.scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              let totalItem = res.totalItems;
              let totalPage =
                parseInt(totalItem / formData.offset) +
                (totalItem % formData.offset === 0 ? 0 : 1);
              setTotalPage(totalPage);
            }
            if (formData.page === 1) setData(res.data);
            else {
              let oldData = [...data];
              oldData.push(...res.data);
              setData(oldData);
            }
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          window.scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [formData]);
  const tabs = [
    {
      id: "0",
      name: ConfigProvider.getTranslation("open"),
    },
    {
      id: "1",
      name: ConfigProvider.getTranslation("successful"),
    },
    {
      id: "2",
      name: ConfigProvider.getTranslation("failed"),
    },
    {
      id: "3",
      name: ConfigProvider.getTranslation("UnderReview"),
    },
  ];

  const [messages, setMessages] = useState([]);
  return (
    <div className="flex justify-center py-3 md:py-10 px-3">
      <div className="flex flex-col w-full lg:w-2/3 max-w-screen-md">
        <div className="text-center border-b border-color-2 pb-4 text-color-1 text-xl sm:text-2xl">
          {ConfigProvider.getTranslation("PaymentTransactionHistory")}
        </div>
        <div className="mt-3 md:mt-10">
          <div className="block">
            <nav
              className="isolate grid grid-cols-2 md:grid-cols-4  divide-x divide-gray-700 divide-y shadow md:rounded-lg"
              aria-label="Tabs"
            >
              {tabs.map((tab, tabIdx) => (
                <div
                  key={tab.id}
                  className={classNames(
                    tab.id === formData.selectedTab
                      ? "bg-layout-border"
                      : "bg-layout-bg",
                    tabIdx === 0 ? "md:rounded-l-lg" : "",
                    tabIdx === tabs.length - 1 ? "md:rounded-r-lg" : "",
                    "group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-sm font-medium text-center cursor-pointer focus:z-10"
                  )}
                  onClick={() => {
                    if (!loading) {
                      setFormData({
                        ...formData,
                        selectedTab: tab.id,
                        page: 1,
                      });
                    }
                  }}
                  aria-current={
                    tab.id === formData.selectedTab ? "page" : undefined
                  }
                >
                  <span>{tab.name}</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      tab.id === formData.selectedTab
                        ? "border-color-2 border-b-2"
                        : "",
                      "absolute inset-x-0 bottom-0 h-0.5"
                    )}
                  />
                </div>
              ))}
            </nav>
          </div>
          {messages.length > 0 && (
            <div className="mt-2 mb-4">
              <Alert messages={messages} setMessages={setMessages} />
            </div>
          )}
          <div className="w-full my-2">
            {loading ? (
              <div className="w-full flex items-center justify-center my-6">
                <LoadingSpinner className="text-color-3 w-12 h-12" />
              </div>
            ) : data.length === 0 ? (
              <div className="text-lg py-20 w-full flex flex-col items-center text-page-text">
                <div>
                  <ExclamationTriangleIcon className="w-24 h-24" />
                </div>
                <div className="my-3 text-white">
                  {ConfigProvider.getTranslation("NoData")}
                </div>
                <div className="my-3">
                  <Link
                    to="/deposit"
                    className="text-layout-bg font-bold bg-gradient-gold-b px-5 py-3 text-sm shadow-sm rounded-full hover:shadow-lg"
                  >
                    {ConfigProvider.getTranslation("Deposit")}
                  </Link>
                </div>
              </div>
            ) : deviceType === "sm" ? (
              data.map((item, a) => (
                <div
                  key={a}
                  className="flex flex-col my-2 border border-layout-border"
                >
                  <div className="flex bg-layout-border p-2 text-xs">
                    <div
                      className={classNames(
                        item.status === 0
                          ? "bg-slate-400"
                          : item.status === 1
                          ? "bg-green-400"
                          : item.status === 2
                          ? "bg-red-400"
                          : item.status === 3
                          ? "bg-yellow-400"
                          : "",
                        "rounded-full px-1.5 mr-2"
                      )}
                    >
                      &nbsp;
                    </div>
                    <div>
                      {new Intl.DateTimeFormat("default", {
                        day: "numeric",
                        month: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        timeZone: prefences.timezone,
                      }).format(new Date(item.date))}
                    </div>
                  </div>
                  <div className="flex bg-layout-bg p-2 text-sm items-center">
                    <div className="grow text-color-3 flex flex-col space-y-2">
                      <div>
                        {item.type === 0
                          ? ConfigProvider.getTranslation("Deposit")
                          : item.type === 1
                          ? ConfigProvider.getTranslation("Withdrawal")
                          : ""}
                        {item.pm !== null && item.pm.length > 0
                          ? " - " + item.pm
                          : null}
                      </div>
                      {Object.keys(item.extra)
                        .filter((x) => x !== "link" && x !== "reference")
                        .map((item2, index2) => (
                          <div
                            key={index2}
                            className="text-sm text-page-text"
                          >{`${item2}: ${item.extra[item2]}`}</div>
                        ))}
                      {item.note && item.note.length && (
                        <div className="text-sm text-page-text">{`${ConfigProvider.getTranslation(
                          "Note"
                        )}: ${item.note}`}</div>
                      )}
                    </div>
                    <div>
                      {item.extra.hasOwnProperty("link") && (
                        <a
                          href={item.extra.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <LinkIcon className="w-4 h-4 mr-2" />
                        </a>
                      )}
                    </div>
                    <div>
                      {item.amount} {item.currency}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <table className="w-full border">
                <thead>
                  <tr className="text-sm">
                    <td className="theme-odd px-2.5 py-2 w-[200px]n text-center">
                      {ConfigProvider.getTranslation("Date")}
                    </td>
                    <td className="theme-odd px-2.5 py-2">
                      {ConfigProvider.getTranslation("PaymentMethod")}
                    </td>
                    <td className="theme-odd px-2.5 py-2 w-40">
                      {ConfigProvider.getTranslation("Amount")}
                    </td>
                    <td className="theme-odd py-2 px-2.5 text-center w-8">
                      <ExclamationCircleIcon className="w-4 h-4" />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, a) => (
                    <tr key={a} className="text-sm">
                      <td className="border border-color-2 px-2.5 py-2 text-center">
                        {new Intl.DateTimeFormat("default", {
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          timeZone: prefences.timezone,
                        }).format(new Date(item.date))}
                      </td>
                      <td className="border border-color-2 px-2.5 py-2 flex flex-col space-y-2">
                        <div>
                          {item.type === 0
                            ? ConfigProvider.getTranslation("Deposit")
                            : item.type === 1
                            ? ConfigProvider.getTranslation("Withdrawal")
                            : ""}
                          {item.pm !== null && item.pm.length > 0
                            ? " - " + item.pm
                            : null}
                        </div>
                        {Object.keys(item.extra)
                          .filter((x) => x !== "link" && x !== "reference")
                          .map((item2, index2) => (
                            <div
                              key={index2}
                              className="text-sm text-page-text"
                            >{`${item2}: ${item.extra[item2]}`}</div>
                          ))}
                        {item.note && item.note.length && (
                          <div className="text-sm text-page-text">{`${ConfigProvider.getTranslation(
                            "Note"
                          )}: ${item.note}`}</div>
                        )}
                      </td>
                      <td className="border border-color-2 px-2.5 py-2 text-center">
                        {item.amount} {item.currency}
                      </td>
                      <td className="border border-color-2 py-2 px-2.5 text-center">
                        {item.extra.hasOwnProperty("link") && (
                          <a
                            href={item.extra.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <LinkIcon className="w-4 h-4 mr-2" />
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {!loading && totalPage > formData.page ? (
              <>
                <hr className="border-0 w-full h-5" />
                <div
                  onClick={() => {
                    setFormData({ ...formData, page: formData.page + 1 });
                  }}
                >
                  <div className="flex w-full justify-center">
                    <button className="background-color-2 text-color-1 text-xs py-2 px-4 rounded flex items-center">
                      <ChevronDownIcon className="w-4 h-4 mr-2" />
                      <span>{ConfigProvider.getTranslation("LoadMore")}</span>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentTransactionHistory;
