import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Requests from "../helpers/Requests";
import { ArrowDownIcon, PrinterIcon } from "@heroicons/react/24/outline";
import { useConfig } from "../context/ConfigContext";

function CMSPage() {
  const navigate = useNavigate();
  const { path } = useParams();
  const [allowPrint, setAllowPrint] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [path]);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const printParam = searchParams.get("print") === "1";
    if (printParam !== allowPrint) {
      setAllowPrint(printParam);
    }
    // eslint-disable-next-line
  }, [window.location.search]);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      const fetchData = async () => {
        const data = await Requests.postData("/general/getPageInfo", {
          path: path,
        });
        return data;
      };

      fetchData()
        .then((response) => {
          const res = response.data;
          if (res.type === "success") {
            setData(res.data);
          } else {
            navigate("/");
          }
          setLoading(false);
        })
        .catch((err) => {
          navigate("/");
          setLoading(false);
        });
    }
  };
  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(
      "<html><head><title>" + data.title + "</title></head><body>"
    );
    printWindow.document.write(data.detail);
    printWindow.document.write("</body></html>");

    printWindow.document.close();
    printWindow.print();
  };
  const ConfigProvider = useConfig();
  return (
    <div className="mx-auto w-full max-w-screen-lg">
      <h3 className="font-semibold text-xl my-4 text-gradient-gold-b flex w-full items-center">
        <div className="grow text-center">
          {loading ? (
            <div className="bg-layout-bg rounded w-full animate-pulse brightness-125">
              &nbsp;
            </div>
          ) : (
            data.title
          )}
        </div>
        {allowPrint && (
          <div className="flex items-center space-x-2">
            <div
              onClick={() => {
                handlePrint();
              }}
              className="bg-gradient-to-r bg-left-title-bg rounded text-left-title-text hover:brightness-95 p-2 cursor-pointer"
              title={ConfigProvider.getTranslation("Print")}
            >
              <PrinterIcon className="w-6 h-6 text-bg-text" />
            </div>
            <a
              href={
                process.env.REACT_APP_API_ENDPOINT +
                "/general/downloadPageInfo?path=" +
                path
              }
              className="inline-block bg-gradient-to-r bg-left-title-bg rounded text-left-title-text hover:brightness-95 p-2 cursor-pointer"
              title={ConfigProvider.getTranslation("Download")}
            >
              <ArrowDownIcon className="w-6 h-6 text-bg-text" />
            </a>
          </div>
        )}
      </h3>
      <div className="p-3">
        <div dangerouslySetInnerHTML={{ __html: data.detail }} />
      </div>
    </div>
  );
}

export default CMSPage;
