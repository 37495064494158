import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { useConfig } from "../../context/ConfigContext";

function Confirm({ title, text, confirm, callback }) {
  const ConfigProvider = useConfig();
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  useEffect(() => {
    if (!open && callback !== null) {
      callback();
    }
    // eslint-disable-next-line
  }, [open]);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-layout-bg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-page-bg px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="flex w-full justify-center">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-300 sm:mx-0 sm:h-10 sm:w-10">
                      <QuestionMarkCircleIcon
                        className="h-6 w-6 text-yellow-500"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <div className="sm:flex sm:items-start sm:justify-center">
                    <div className="mt-3 text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-color-3"
                      >
                        {title}
                      </Dialog.Title>
                      <div className="mt-2 text-sm">{text}</div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-400 text-page-bg hover:bg-green-500 px-3 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:w-auto"
                    onClick={() => {
                      setOpen(false);
                      confirm();
                    }}
                  >
                    {ConfigProvider.getTranslation("Confirm")}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center px-3 py-2 text-sm font-semibold shadow-sm bg-menu-bg hover:brightness-110 rounded-md sm:mt-0 sm:w-auto outline-0 text-slate-300 bg-slate-500 hover:bg-slate-600"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    {ConfigProvider.getTranslation("Cancel")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Confirm;
