import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React from "react";

function Alert({ messages, setMessages }) {
  return messages.map((item, key) => (
    <div
      key={key}
      className={
        "rounded-md p-4 mt-2 blinking-element " +
        (item.type === "success"
          ? " bg-green-50"
          : item.type === "warning"
          ? " bg-yellow-50"
          : "bg-red-50")
      }
    >
      <div className="flex">
        <div className="flex-shrink-0">
          {item.type === "success" ? (
            <CheckCircleIcon
              className="w-5 h-5 text-green-400"
              aria-hidden="true"
            />
          ) : item.type === "warning" ? (
            <ExclamationTriangleIcon
              className="w-5 h-5 text-yellow-400"
              aria-hidden="true"
            />
          ) : (
            <XCircleIcon className="w-5 h-5 text-red-400" aria-hidden="true" />
          )}
        </div>
        <div className="ml-3">
          <p
            className={
              "text-sm font-medium " +
              (item.type === "success"
                ? " text-green-800"
                : item.type === "warning"
                ? "text-yellow-800"
                : "text-red-800")
            }
          >
            {item.text}
          </p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className={
                "inline-flex rounded-md p-1.5  focus:outline-none focus:ring-2 focus:ring-offset-2 " +
                (item.type === "success"
                  ? "bg-green-50 text-green-500"
                  : item.type === "warning"
                  ? "text-yellow-500"
                  : "bg-red-50 text-red-500")
              }
            >
              <XMarkIcon
                className="h-5 w-5"
                aria-hidden="true"
                onClick={() => {
                  setMessages((prev) => prev.filter((x, i) => i !== key));
                }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  ));
}

export default Alert;
