import React, { useEffect, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import { useNavigate } from "react-router-dom";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "../components/Common/Loading/LoadingSpinner";
import Alert from "../components/Common/Alert";
import Requests from "../helpers/Requests";

function ValidateEmail() {
  const ConfigProvider = useConfig();
  const navigate = useNavigate();
  useEffect(() => {
    if (ConfigProvider.config.user) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);

  const [messages, setMessages] = useState([]);
  const validateEmail = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);

      const urlParams = new URLSearchParams(window.location.search);
      const user = urlParams.get("user");
      const code = urlParams.get("code");
      Requests.postData("/user/validateEmail", {
        code: code ?? "",
        user: user ?? "",
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            window.scrollTo(0, 0);
          } else {
            setMessages([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation(
                      "YourEmailHasBeenSuccessfullyVerified"
                    ),
              },
            ]);
            setTimeout(() => {
              window.location.href = "/";
            }, 2000);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    validateEmail();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col items-center justify-center py-3 md:py-10 px-3 z-10">
      <div className="flex flex-col w-full max-w-lg ">
        <div className="self-center text-xl sm:text-2xl text-color-1">
          {ConfigProvider.getTranslation("EmailValidation")}
        </div>

        <div className="mt-3 md:mt-10">
          <div className="mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
          <div className="flex w-full">
            <button
              type="button"
              className="flex items-center justify-center focus:outline-none text-sm sm:text-base rounded py-2 w-full transition duration-150 ease-in bg-home-from-8 text-page-bg font-bold"
              disabled={loading}
              onClick={() => {
                navigate("/");
              }}
            >
              <span className="mr-2 uppercase">
                {ConfigProvider.getTranslation("Home")}
              </span>
              {loading ? (
                <span>
                  <LoadingSpinner className="w-6 h-6" />
                </span>
              ) : (
                <span>
                  <ArrowRightCircleIcon className="w-6 h-6" />
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ValidateEmail;
