import React, { useState, useEffect } from "react";
import { useConfig } from "../context/ConfigContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import Requests from "../helpers/Requests";
import { randomArray } from "../helpers/Helpers";
import Promotion from "./Promotion";
import Modal from "../components/Common/Modal";

function Promotions() {
  const ConfigProvider = useConfig();
  const { _id } = useParams();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      const fetchData = async () => {
        const data = await Requests.postData("/general/getPromotions", {});
        return data;
      };

      fetchData()
        .then((response) => {
          const res = response.data;
          if (res.type === "success") {
            setData(res.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          Requests.diagnostics(err, navigate);
          setLoading(false);
        });
    }
  };

  const [promotionModal, setPromotionModal] = useState(_id ? true : false);
  useEffect(() => {
    if (_id) {
      if (!promotionModal) {
        setPromotionModal(true);
      }
    } else {
      if (promotionModal) {
        setPromotionModal(false);
      }
    }
    // eslint-disable-next-line
  }, [_id]);

  return (
    <div className="mx-auto w-full py-3 px-3 max-w-screen-2xl">
      <div className="py-5 text-color-3">
        <div className="w-full md:flex">
          <div className="grow">
            <div className="w-full text-center pb-4">
              <h2 className="font-semibold text-xl text-left">
                {ConfigProvider.getTranslation("Promotions")}
              </h2>
              <hr className="border-b w-full mt-2 pb-4" />
              <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                {(loading ? randomArray(10) : data).map((item, index) => (
                  <Link
                    className="w-full group transition overflow-hidden rounded duration-1000 cursor-pointer relative"
                    key={index}
                    to={"/promotions/" + item._id}
                  >
                    {loading ? (
                      <div className="aspect-[16/9] bg-layout-bg rounded-t">
                        &nbsp;
                      </div>
                    ) : (
                      <img
                        srcSet={
                          process.env.REACT_APP_CDN_ENDPOINT +
                          "/upload/img/" +
                          item.image
                        }
                        alt=""
                        className="w-full rounded-t transition group-hover:scale-105"
                      />
                    )}
                    <div className="w-full bg-border rounded-b font-medium text-left px-2 text-sm py-1 truncate">
                      {loading ? (
                        <div className="bg-layout-bg rounded w-full animate-pulse">
                          &nbsp;
                        </div>
                      ) : (
                        item.title
                      )}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {promotionModal && (
        <Modal
          callback={() => {
            navigate("/promotions");
          }}
          showLogo={false}
        >
          <Promotion
            callback={() => {
              navigate("/promotions");
            }}
            _id={_id}
          />
        </Modal>
      )}
    </div>
  );
}

export default Promotions;
