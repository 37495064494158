import React, { useEffect, useRef, useState } from "react";
import { Header } from "../components/Common/Header/Header";
import { useParams } from "react-router-dom";
import { useDeviceSize } from "../context/DeviceSizeContext";
import {
  ArrowPathIcon,
  ArrowsPointingOutIcon,
  StarIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { StarIcon as StarOpenIcon } from "@heroicons/react/24/outline";
import { useFavorites } from "../context/FavoritesContext";
import { useConfig } from "../context/ConfigContext";
import JackpotContainer from "../components/Common/JackpotContainer";

function CasinoGame() {
  const searchParams = new URLSearchParams(window.location.search);
  const demo = searchParams.get("demo") === "1";
  const { id, size } = useParams();
  const DeviceSizeProvider = useDeviceSize();
  const FavoritesProvider = useFavorites();
  const iframeRef = useRef(null);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  // eslint-disable-next-line
  const [settingSidebarOpen, setSettingSidebarOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const removeOtherFields = () => {
    const rootElementId = "root";
    const bodyChildren = document.body.children;
    const elementsToRemove = [];

    for (let i = 0; i < bodyChildren.length; i++) {
      const child = bodyChildren[i];

      if (child.id !== rootElementId) {
        child.style.display = "none";
        elementsToRemove.push(child);
      }
    }

    elementsToRemove.forEach((element) => element.remove());
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (DeviceSizeProvider.deviceType === "sm") {
        setShowHeader(false);
        removeOtherFields();
      }
      try {
        // eslint-disable-next-line
        eval("Comm100API.set('livechat.button.isVisible', false)");
      } catch (e) {}
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, []);

  const [showFrame, setShowFrame] = useState(true);

  useEffect(() => {
    if (window !== window.parent) {
      let currentWindow = window;
      while (currentWindow !== currentWindow.parent) {
        currentWindow = currentWindow.parent;
      }
      currentWindow.postMessage("exit", "*");
    }
  }, []);
  useEffect(() => {
    if (window === window.parent) {
      const handleMessageFromIframe = (event) => {
        const receivedMessage = event.data;

        if (receivedMessage === "exit") {
          window.location.href = "/";
        }
      };

      window.addEventListener("message", handleMessageFromIframe);

      return () => {
        window.removeEventListener("message", handleMessageFromIframe);
      };
    }
  }, []);
  const ConfigProvider = useConfig();
  return window !== window.parent ? null : (
    <div>
      {showHeader && (
        <Header
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
          setSettingSidebarOpen={setSettingSidebarOpen}
          onlyLogo={true}
        />
      )}

      {DeviceSizeProvider.deviceType === "sm" ? (
        <div className="flex w-full h-full">
          <IFrameComponent
            id={id}
            deviceType={DeviceSizeProvider.deviceType}
            iframeRef={iframeRef}
            demo={demo}
          />
        </div>
      ) : size === "full" ? (
        <div className="flex w-full h-full mt-3">
          <IFrameComponent
            id={id}
            deviceType={DeviceSizeProvider.deviceType}
            iframeRef={iframeRef}
            size={size}
            demo={demo}
          />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center py-3 md:py-10 px-3">
          <div className="flex w-full max-w-screen-2xl background-color-5 p-5 rounded-sm">
            {showFrame ? (
              <IFrameComponent
                id={id}
                deviceType={DeviceSizeProvider.deviceType}
                iframeRef={iframeRef}
                demo={demo}
              />
            ) : (
              <div className="background-color-3 aspect-[16/9] w-full">
                &nbsp;
              </div>
            )}
            <div className="flex flex-col  bg-layout-bg p-2  rounded-r-lg gap-2">
              <button
                type="button"
                className="background-color-1 p-1 rounded"
                onClick={() => {
                  window.history.back(-2);
                }}
              >
                <XMarkIcon className="w-6 h-6" />
              </button>
              <button
                type="button"
                className="background-color-1 p-1 rounded"
                onClick={() => {
                  const iframe = iframeRef.current;
                  if (iframe) {
                    if (iframe.requestFullscreen) {
                      iframe.requestFullscreen();
                    } else if (iframe.mozRequestFullScreen) {
                      iframe.mozRequestFullScreen();
                    } else if (iframe.webkitRequestFullscreen) {
                      iframe.webkitRequestFullscreen();
                    } else if (iframe.msRequestFullscreen) {
                      iframe.msRequestFullscreen();
                    }
                  }
                }}
              >
                <ArrowsPointingOutIcon className="w-6 h-6" />
              </button>
              <button
                type="button"
                className="background-color-1 p-1 rounded"
                onClick={() => {
                  setShowFrame(false);
                  setTimeout(() => {
                    setShowFrame(true);
                  }, 1000);
                }}
              >
                <ArrowPathIcon className="w-6 h-6" />
              </button>
              <button type="button" className="background-color-1 p-1 rounded">
                {FavoritesProvider.casinoGames.hasOwnProperty(id) ? (
                  <StarIcon
                    className="w-6 h-6"
                    onClick={() => {
                      FavoritesProvider.toggleCasinoGames(id);
                    }}
                  />
                ) : (
                  <StarOpenIcon
                    className="w-6 h-6"
                    onClick={() => FavoritesProvider.toggleCasinoGames(id)}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      {ConfigProvider.config.user && ConfigProvider.config.user.jackpotPrize ? (
        <JackpotContainer />
      ) : null}
    </div>
  );
}
function IFrameComponent({ id, deviceType, iframeRef, size, demo = 1 }) {
  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

  return (
    <iframe
      ref={iframeRef}
      title="Game"
      width="100%"
      height="100%"
      className={
        deviceType === "sm"
          ? "min-h-dvh"
          : size === "full"
          ? "h-[calc(100vh-85px)]"
          : "aspect-[16/9] rounded-l-lg"
      }
      frameBorder="0"
      allowFullScreen
      src={
        apiEndpoint +
        "/casino/launchGame?id=" +
        id +
        "&demo=" +
        (demo ? "1" : 0)
      }
    ></iframe>
  );
}

export default CasinoGame;
