import React, { useState } from "react";
import { useConfig } from "../../../context/ConfigContext";

function GenderRadio({ onUpdate, val }) {
  let ConfigProvider = useConfig();
  const elements = [
    { id: "M", name: ConfigProvider.getTranslation("Male") },
    { id: "F", name: ConfigProvider.getTranslation("Female") },
  ];

  const [itemValue, setItemValue] = useState(val && val !== null ? val : "");
  const handleChange = (e) => {
    const { value } = e.target;
    setItemValue(value);
    onUpdate(value);
  };
  return (
    <fieldset className="mt-4">
      <legend className="sr-only">
        {ConfigProvider.getTranslation("Gender")}
      </legend>
      <div className="grid grid-cols-2 justify-items-center space-x-10">
        {elements.map((item) => (
          <div key={item.id} className="flex items-center">
            <input
              id={item.id}
              name="gender"
              type="radio"
              className="h-4 w-4 ring-0"
              required
              onChange={handleChange}
              value={item.id}
              checked={itemValue === item.id}
            />
            <label htmlFor={item.id} className="ml-3 block text-sm">
              {item.name}
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  );
}

export default GenderRadio;
