import React, { useEffect, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import { Link, useNavigate } from "react-router-dom";
import {
  KeyIcon,
  UserIcon,
  UserPlusIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import Requests from "../helpers/Requests";
import LoadingSpinner from "../components/Common/Loading/LoadingSpinner";
import Alert from "../components/Common/Alert";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/solid";

function Login({ callback }) {
  const ConfigProvider = useConfig();
  const navigate = useNavigate();
  useEffect(() => {
    if (ConfigProvider.config.user) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    uname: "",
    password: "",
  });
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  const [messages, setMessages] = useState([]);
  const [showGA, setShowGA] = useState(false);
  const updateForm = (e) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      setShowGA(false);
      setMessages([]);
      Requests.postData("/user/login", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            window.scrollTo(0, 0);
          } else if (res.data) {
            setMessages([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            setTimeout(() => {
              window.location.href = "/";
            }, 2000);
          } else {
            setShowGA(true);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const [showPassword, setShowPassword] = useState(false);
  const [code, setCode] = useState("");
  const updateForm2 = (e) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      setMessages([]);
      Requests.postData("/user/confirmGa", { code: code })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            window.scrollTo(0, 0);
          } else {
            setMessages([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            setTimeout(() => {
              window.location.href = "/";
            }, 2000);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  return (
    <div className="flex flex-col items-center justify-center py-3 md:py-10 px-3">
      <div className="flex flex-col w-full max-w-lg ">
        {!showGA ? (
          <div className="self-center text-xl sm:text-2xl font-bold text-white">
            {ConfigProvider.getTranslation("LoginButton")}
          </div>
        ) : (
          <div className="self-center text-xl sm:text-2xl font-bold text-white">
            {ConfigProvider.getTranslation("2FA")}
          </div>
        )}

        {!showGA ? (
          <>
            <div className="mt-3 md:mt-10">
              <form onSubmit={updateForm} autoComplete="off">
                <div className="mt-2 mb-4">
                  <Alert messages={messages} setMessages={setMessages} />
                </div>
                <div className="flex flex-col mb-6">
                  <div className="relative">
                    <div className="inline-flex text-page-text items-center justify-center absolute left-0 top-0 h-full w-10">
                      <UserIcon className="w-6 h-6 " />
                    </div>
                    <input
                      id="uname"
                      name="uname"
                      type="text"
                      className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md pl-10 pr-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                      placeholder={ConfigProvider.getTranslation("Username")}
                      value={formData.uname}
                      onChange={handleChange}
                      required
                      disabled={loading}
                    />
                  </div>
                </div>
                <div className="flex flex-col mb-6">
                  <div className="relative">
                    <div className="inline-flex text-page-text items-center justify-center absolute left-0 top-0 h-full w-10">
                      <KeyIcon className="w-6 h-" />
                    </div>

                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md pl-10 pr-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                      placeholder={ConfigProvider.getTranslation("Password")}
                      value={formData.password}
                      onChange={handleChange}
                      required
                      disabled={loading}
                    />
                    <div
                      className="inline-flex items-center justify-center absolute right-0 top-0 h-full w-10 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {!showPassword ? (
                        <EyeIcon className="w-4 h-4 text-color-3" />
                      ) : (
                        <EyeSlashIcon className="w-4 h-4 text-color-3" />
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex items-center mb-6 -mt-4">
                  <div className="flex ml-auto">
                    <Link
                      to="/forgotPassword"
                      onClick={() => {
                        callback && callback();
                      }}
                      className="inline-flex text-xs sm:text-sm text-page-text font-bold"
                    >
                      {ConfigProvider.getTranslation("LostPassword")}
                    </Link>
                  </div>
                </div>

                <div className="flex w-full">
                  <button
                    type="submit"
                    className="flex items-center justify-center focus:outline-none text-sm sm:text-base rounded py-2 w-full transition duration-150 ease-in bg-home-from-3 font-bold"
                    disabled={loading}
                  >
                    {loading ? (
                      <span>
                        <LoadingSpinner className="w-6 h-6 mr-3" />
                      </span>
                    ) : (
                      <span>
                        <ArrowRightOnRectangleIcon className="w-6 h-6 mr-3" />
                      </span>
                    )}
                    <span>{ConfigProvider.getTranslation("LoginButton")}</span>
                  </button>
                </div>
              </form>
            </div>
            <div className="flex justify-center items-center mt-6">
              <Link
                to="/registerFull"
                onClick={() => {
                  callback && callback();
                }}
                className="flex items-center justify-center focus:outline-none text-sm sm:text-base rounded py-2 w-full transition duration-150 ease-in bg-home-from-1 text-page-bg font-bold"
              >
                <span>
                  <UserPlusIcon className="w-6 h-6" />
                </span>
                <span className="ml-2">
                  {ConfigProvider.getTranslation("Register")}
                </span>
              </Link>
            </div>
          </>
        ) : (
          <div className="mt-3 md:mt-10">
            <form onSubmit={updateForm2} autoComplete="off">
              <div className="mt-2 mb-4">
                <Alert messages={messages} setMessages={setMessages} />
              </div>

              <div className="flex flex-col mb-6">
                <div className="relative">
                  <div className="inline-flex text-page-text items-center justify-center absolute left-0 top-0 h-full w-10">
                    <KeyIcon className="w-6 h-" />
                  </div>

                  <input
                    id="code"
                    name="code"
                    type="text"
                    className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md pl-10 pr-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                    placeholder={ConfigProvider.getTranslation("Code")}
                    value={code}
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                    required
                    disabled={loading}
                    maxLength={6}
                  />
                </div>
              </div>

              <div className="flex w-full">
                <button
                  type="submit"
                  className="flex items-center justify-center focus:outline-none text-sm sm:text-base rounded py-2 w-full transition duration-150 ease-in bg-home-from-3 font-bold"
                  disabled={loading}
                >
                  {loading ? (
                    <span>
                      <LoadingSpinner className="w-6 h-6 mr-3" />
                    </span>
                  ) : (
                    <span>
                      <ArrowRightOnRectangleIcon className="w-6 h-6 mr-3" />
                    </span>
                  )}
                  <span>{ConfigProvider.getTranslation("LoginButton")}</span>
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
