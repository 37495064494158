import React, { useEffect, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import { Link, useNavigate } from "react-router-dom";
import Requests from "../helpers/Requests";

function Deposit() {
  const ConfigProvider = useConfig();
  const navigate = useNavigate();
  useEffect(() => {
    if (ConfigProvider.config.user === null) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(true);
  const [pmList, setPmList] = useState([]);
  useEffect(() => {
    Requests.postData("/accounting/getPaymentMethods", { type: 0 })
      .then((response) => {
        let res = response.data;
        if (res.type !== "success") {
          window.location.href = "/";
        } else {
          let newData = res.data;
          setPmList(newData);
          setLoading(false);
        }
      })
      .catch(() => {
        window.location.href = "/";
      });
  }, []);
  return loading ? (
    <div className="flex justify-center py-3 md:py-10 px-3">
      <div className="flex flex-col w-full lg:w-2/3 max-w-screen-md">
        <div className="border-b border-color-2 pb-4">
          <div className="w-full">
            <div
              className={
                (loading ? "animate-pulse " : "") +
                "w-full bg-layout-bg rounded py-3"
              }
            >
              &nbsp;
            </div>
          </div>
        </div>
        <div className="mt-3 md:mt-10">
          <div className="w-full px-4 lg:grid lg:grid-cols-2 lg:gap-4">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, key) => (
              <div
                key={key}
                className={
                  (loading ? "animate-pulse " : "") +
                  "w-full bg-layout-bg rounded h-16 px-2 mb-4 lg:mb-0"
                }
              >
                &nbsp;
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex justify-center py-3 md:py-10 px-3">
      <div className="flex flex-col w-full lg:w-2/3 max-w-screen-md">
        <div className="text-center border-b border-color-2 pb-4 text-color-1 text-xl sm:text-2xl">
          {ConfigProvider.getTranslation("Deposit")}
        </div>
        <div className="mt-3 md:mt-10">
          <div className="w-full px-4 lg:grid lg:grid-cols-2 lg:gap-4">
            {pmList.map((item, key) => (
              <Link
                to={"/depositForm/" + item._id}
                key={key}
                className="flex w-full justify-center items-center button-odd rounded px-2 space-x-2 cursor-pointer mb-4 min-h-[65px] lg:mb-0"
              >
                <div className="w-1/3 py-2 justify-center flex">
                  <img
                    srcSet={
                      process.env.REACT_APP_CDN_ENDPOINT +
                      "/upload/img/paymentMethods/" +
                      item.icon
                    }
                    alt={item.name + " logo"}
                    className="h-fit max-h-12"
                  />
                </div>
                <div className="w-2/3">
                  {ConfigProvider.translateItem(item)}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Deposit;
