import React from "react";
import { useConfig } from "../../context/ConfigContext";

function Marquee() {
  const ConfigProvider = useConfig();
  const conf = ConfigProvider.config;
  return (
    <div className="marquee h-9 text-sm bg-page-bg text-page-text">
      <ul className="marquee__content">
        {[
          ...conf.announcements,
          ...conf.announcements,
          ...conf.announcements,
        ].map((item, index) => (
          <div
            className="marquee__item"
            key={index}
            dangerouslySetInnerHTML={{
              __html: ConfigProvider.translateItem(item),
            }}
          ></div>
        ))}
      </ul>
      <ul aria-hidden className="marquee__content">
        {[
          ...conf.announcements,
          ...conf.announcements,
          ...conf.announcements,
        ].map((item, index) => (
          <div
            className="marquee__item"
            key={index}
            dangerouslySetInnerHTML={{
              __html: ConfigProvider.translateItem(item),
            }}
          ></div>
        ))}
      </ul>
    </div>
  );
}

export default Marquee;
