import React, { useEffect, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "../components/Common/Loading/LoadingSpinner";
import Alert from "../components/Common/Alert";
import Requests from "../helpers/Requests";

function WithdrawalForm() {
  const ConfigProvider = useConfig();
  let { pm } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (ConfigProvider.config.user === null) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const [formArray, setFormArray] = useState([]);
  const [formData, setFormData] = useState({ method: pm });
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  const [messages, setMessages] = useState([]);
  const [name, setName] = useState("");
  const updateForm = (e) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      setMessages([]);
      let requiredFieldErros = [];

      if (requiredFieldErros.length > 0) {
        setMessages([
          {
            type: "error",
            text:
              "[" +
              requiredFieldErros.join(",") +
              "] " +
              ConfigProvider.getTranslation("Forms.EmptyValueError"),
          },
        ]);
        window.scrollTo(0, 0);
        setLoading(false);
      } else {
        Requests.postData("/accounting/createWithdrawalRequest", formData)
          .then((response) => {
            let res = response.data;
            if (res.type !== "success") {
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
              window.scrollTo(0, 0);
            } else {
              setMessages([
                {
                  type: "success",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.SuccessMessage"),
                },
              ]);
              setFormData({ method: pm });
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };
  useEffect(() => {
    Requests.postData("/accounting/paymentMethodDetail", { type: 1, id: pm })
      .then((response) => {
        let res = response.data;
        if (res.type !== "success") {
          navigate("/withdrawal");
        } else {
          setName(res.name);
          setFormArray(res.data);
          let newFormData = { method: pm };
          res.data.forEach((item) => {
            newFormData[item.id] = "";
          });
          setFormData(newFormData);
          setFirstLoading(false);
        }
      })
      .catch(() => {
        navigate("/withdrawal");
      });
    // eslint-disable-next-line
  }, []);
  return firstLoading ? (
    <></>
  ) : (
    <div className="flex justify-center py-3 md:py-10 px-3">
      <div className="flex flex-col w-full lg:w-2/3 max-w-screen-md">
        <form className="flex-none w-full" onSubmit={updateForm}>
          {messages.length > 0 && (
            <div className="mt-2 mb-4">
              <Alert messages={messages} setMessages={setMessages} />
            </div>
          )}
          <div className="w-full p-4">
            <div className="w-full text-center text-lg py-2 border-b border-color-2 mb-5">
              {ConfigProvider.getTranslation("Withdrawal") + " - " + name}
            </div>
            {formArray.map((item) => (
              <div key={item.id} className="flex flex-col mb-6">
                <label
                  htmlFor={item.id}
                  className="mb-1 text-xs sm:text-sm tracking-wide"
                >
                  {item.name}
                </label>
                <div className="relative">
                  {item.type === "text" || item.type === "number" ? (
                    <input
                      id={item.id}
                      key={item.id}
                      name={item.id}
                      type={item.type}
                      className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                      onChange={handleChange}
                      required
                      disabled={loading}
                      step={0.01}
                      maxLength={item.maxLength}
                    />
                  ) : item.type === "select" ? (
                    <select
                      id={item.id}
                      key={item.id}
                      name={item.id}
                      onChange={handleChange}
                      required
                      className="w-full rounded-md px-4 py-4 text-left text-color-4 shadow-sm ring-1 ring-inset ring-black-300 bg-layout-bg text-base ring-black ring-opacity-5 focus:outline-none  sm:text-sm sm:leading-6"
                      disabled={loading}
                    >
                      <option value="">
                        {ConfigProvider.getTranslation("Drowndown.SelectOne")}
                      </option>
                      {Object.keys(item.options).map((option) => (
                        <option value={option} key={option}>
                          {item.options[option]}
                        </option>
                      ))}
                    </select>
                  ) : null}
                </div>
              </div>
            ))}

            <div className="flex flex-col">
              <button
                type="submit"
                className="flex items-center justify-center focus:outline-none text-sm sm:text-base rounded py-2 w-full transition duration-150 ease-in bg-home-from-4 text-page-bg font-bold"
                disabled={loading}
              >
                <span className="mr-2 uppercase">
                  {ConfigProvider.getTranslation("Button.Send")}
                </span>
                {loading ? (
                  <span>
                    <LoadingSpinner className="w-6 h-6" />
                  </span>
                ) : (
                  <span>
                    <ArrowRightCircleIcon className="w-6 h-6" />
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default WithdrawalForm;
