import React, { useEffect, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import { Link } from "react-router-dom";
import { classNames } from "../helpers/Helpers";
import Dropdown from "./Common/FormElements/Dropdown";

function PaginationClassic({ page, setPage, totalItems, offset }) {
  const ConfigProvider = useConfig();
  const totalPage =
    parseInt(totalItems / offset) + (totalItems % offset === 0 ? 0 : 1);

  return (
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
      <nav
        className="mb-4 sm:mb-0 sm:order-1"
        role="navigation"
        aria-label="Navigation"
      >
        <ul className="flex items-center justify-center">
          <li className="ml-3 first:ml-0 mt-2">
            <Link
              className={classNames(
                page < 2
                  ? "text-slate-600 cursor-default"
                  : "hover:border-slate-300 text-page-text"
              )}
              onClick={() => {
                if (page > 1) {
                  setPage(page - 1);
                }
              }}
            >
              &lt;
            </Link>
          </li>
          <li className="ml-3 first:ml-0 relative">
            <PageDropdown
              totalPage={totalPage}
              selected={page}
              setSelected={(option) => {
                setPage(option);
              }}
            />
          </li>
          <li className="ml-3 first:ml-0 mt-2">
            <Link
              className={classNames(
                page >= totalPage
                  ? "text-slate-600 cursor-default"
                  : "hover:border-slate-300 text-page-text"
              )}
              onClick={() => {
                if (page < totalPage) {
                  setPage(page + 1);
                }
              }}
            >
              &gt;
            </Link>
          </li>
        </ul>
      </nav>
      <div className="text-sm text-page-text text-center sm:text-left">
        {ConfigProvider.getTranslation("Total")}
        {": "}
        <span className="font-semibold text-home-from-1">{totalItems}</span>
      </div>
      <div className="w-full h-[20px]">&nbsp;</div>
    </div>
  );
}
function PageDropdown({ totalPage, selected, setSelected }) {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    let send = [];
    for (let i = 0; i < totalPage; i++) {
      send.push({ id: i + 1, name: i + 1 });
    }

    setOptions(send);
  }, [totalPage]);

  return (
    <Dropdown
      name=""
      selected={selected}
      setSelected={setSelected}
      items={options}
      direction="top"
    />
  );
}
export default PaginationClassic;
