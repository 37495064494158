import React, { Fragment, useState } from "react";
import { useConfig } from "../../../context/ConfigContext";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../../helpers/Helpers";

function CurrencyDropdown({ onUpdate, val }) {
  const ConfigProvider = useConfig();
  const items = Object.keys(ConfigProvider.config.currencies).map((item) => ({
    id: item,
    name: item,
  }));
  const [selected, setSelected] = useState(val && val !== null ? val : null);

  const updateItem = (item) => {
    setSelected(item);
    onUpdate(item.id);
  };
  return (
    <Listbox value={selected} onChange={updateItem} required>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6">
            {ConfigProvider.getTranslation("Currency")}:
            <span className="text-red-500">*</span>
          </Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full text-page-text cursor-default rounded-md py-1.5 pl-3 pr-10 bg-layout-bg text-left shadow-sm border-2 border-layout-border focus:outline-none  sm:text-sm sm:leading-6">
              <span className="flex items-center">
                <span className="ml-3 block truncate">
                  {selected
                    ? selected.name
                    : ConfigProvider.getTranslation("Drowndown.SelectOne")}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-layout-bg py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {items.map((item, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      classNames(
                        active ? "text-page-text" : "text-white",
                        "relative cursor-default select-none py-2 pl-3 pr-9",
                        selected && selected.id === item.id ? "bg-menu-bg" : ""
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={
                              (selected ? "font-semibold" : "font-normal") +
                              "ml-3 block truncate"
                            }
                          >
                            {item.name}
                          </span>
                        </div>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export default CurrencyDropdown;
