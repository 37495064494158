import React, { useEffect, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import LoadingSpinner from "../components/Common/Loading/LoadingSpinner";
import {
  ExclamationTriangleIcon,
  ArrowRightCircleIcon,
  EnvelopeIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { usePrefences } from "../context/PrefencesContext";
import Requests from "../helpers/Requests";
import Alert from "../components/Common/Alert";
import { formatDate, addDays, getDate } from "../helpers/Helpers";
import Dropdown from "../components/Common/FormElements/Dropdown";
import PaginationClassic from "../components/PaginationClassic";

function Messages() {
  const ConfigProvider = useConfig();
  const navigate = useNavigate();
  useEffect(() => {
    if (ConfigProvider.config.user === null) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const [formData, setFormData] = useState({
    date1: formatDate(addDays(getDate(), -15)),
    date2: formatDate(new Date()),
    page: 1,
    offset: 30,
    status: "0",
  });
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  const [data, setData] = useState([]);
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      Requests.postData("/user/getMessages", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            window.scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotalItems(res.totalItems);
            }
            setData(res.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          Requests.diagnostics(err);
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          window.scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [formData.page, formData.offset]);

  const allStatus = [
    {
      id: "-1",
      name: ConfigProvider.getTranslation("Filter.All"),
    },
    {
      id: "0",
      name: ConfigProvider.getTranslation("Pending"),
    },
    {
      id: "1",
      name: ConfigProvider.getTranslation("Seen"),
    },
  ];

  const [messages, setMessages] = useState([]);
  return (
    <div className="flex justify-center py-3 md:py-10 px-3">
      <div className="flex flex-col w-full xl:mx-20  max-w-screen-l">
        <div className="text-center border-b border-color-2 pb-4 text-color-1 text-xl sm:text-2xl">
          {ConfigProvider.getTranslation("Messages")}
        </div>
        <div className="mt-3 md:mt-10">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              getItems();
            }}
          >
            <div className="lg:grid grid-cols-2 lg:grid-cols-4 shadow bg-layout-border gap-2 p-2">
              <div className="mb-2 relative">
                <Dropdown
                  items={allStatus}
                  name={ConfigProvider.getTranslation("Status")}
                  selected={formData.status}
                  setSelected={(val) => {
                    setFormData({ ...formData, status: val });
                  }}
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="date1"
                  className="block text-sm font-medium leading-6"
                >
                  {ConfigProvider.getTranslation("StartDate")}:
                  <span className="text-red-500">*</span>
                </label>
                <div className="relative mt-2">
                  <input
                    id="date1"
                    name="date1"
                    type="date"
                    className="w-full placeholder:text-page-text py-1.5 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                    placeholder={ConfigProvider.getTranslation("StartDate")}
                    value={formData.date1}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="mb-2">
                <label
                  htmlFor="date2"
                  className="block text-sm font-medium leading-6"
                >
                  {ConfigProvider.getTranslation("EndDate")}:
                  <span className="text-red-500">*</span>
                </label>
                <div className="relative mt-2">
                  <input
                    id="date2"
                    name="date2"
                    type="date"
                    className="w-full placeholder:text-page-text py-1.5 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                    placeholder={ConfigProvider.getTranslation("EndDate")}
                    value={formData.date2}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium leading-6">
                  &nbsp;
                </label>
                <div className="relative mt-2">
                  <button
                    type="submit"
                    className="flex items-center justify-center focus:outline-none text-sm sm:text-base rounded py-1.5 w-full transition duration-150 ease-in bg-home-from-1 text-page-bg font-bold"
                    disabled={loading}
                  >
                    <span className="mr-2 uppercase">
                      {ConfigProvider.getTranslation("Button.Send")}
                    </span>
                    {loading ? (
                      <span>
                        <LoadingSpinner className="w-6 h-6" />
                      </span>
                    ) : (
                      <span>
                        <ArrowRightCircleIcon className="w-6 h-6" />
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
          {messages.length > 0 && (
            <div className="mt-2 mb-4">
              <Alert messages={messages} setMessages={setMessages} />
            </div>
          )}
          <div className="w-full my-2">
            {loading ? (
              <div className="w-full flex items-center justify-center my-6">
                <LoadingSpinner className="text-color-3 w-12 h-12" />
              </div>
            ) : data.length === 0 ? (
              <div className="text-lg py-20 w-full flex flex-col items-center text-page-text">
                <div>
                  <ExclamationTriangleIcon className="w-24 h-24" />
                </div>
                <div className="my-3 text-white">
                  {ConfigProvider.getTranslation("NoData")}
                </div>
              </div>
            ) : (
              data.map((item, index) => {
                return (
                  <MessageItem key={index} item={item} setData={setData} />
                );
              })
            )}
            {/* Pagination */}
            <div className="mt-8">
              <PaginationClassic
                page={formData.page}
                setPage={(val) => {
                  setFormData((prev) => {
                    return { ...prev, page: val };
                  });
                }}
                totalItems={totalItems}
                offset={formData.offset}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function MessageItem({ item, setData }) {
  const prefences = usePrefences();
  const [opened, setOpened] = useState(false);
  useEffect(() => {
    if (opened && item.status === 0) {
      Requests.postData("/user/readMessage", { _id: item._id })
        .then((response) => {
          let res = response.data;
          if (res.type === "success") {
            setData((prev) => {
              const index = prev.findIndex((i) => i._id === item._id);
              if (index !== -1) {
                const updatedItems = [
                  ...prev.slice(0, index),
                  { ...prev[index], status: 1 },
                  ...prev.slice(index + 1),
                ];

                return updatedItems;
              }

              return prev;
            });
          }
        })
        .catch(() => {});
    }
    // eslint-disable-next-line
  }, [opened]);

  return (
    <div
      className={`flex flex-col w-full my-2 bg-layout-bg border border-layout-border cursor-pointer ${
        item.status === 0 ? "animate-pulse" : ""
      }`}
      onClick={() => setOpened(!opened)}
    >
      <div className="flex items-center p-2 text-xs">
        <div className="w-8 h-8 rounded-full shrink-0 flex items-center justify-center">
          <EnvelopeIcon className="w-4 h-4 text-white" />
        </div>
        <div className="grow px-2">
          <div className="flex text-xs mb-2">
            <div className="grow">
              {new Intl.DateTimeFormat("default", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                timeZone: prefences.timezone,
              }).format(new Date(item.date))}
            </div>
          </div>
          <div className="flex text-sm items-center gap-2">
            <div className="text-xs grow">{item.title}</div>
          </div>
        </div>
        <div className="w-8 h-8 rounded-full shrink-0 flex items-center justify-center">
          {opened ? (
            <ChevronUpIcon className="w-4 h-4 text-white" />
          ) : (
            <ChevronDownIcon className="w-4 h-4 text-white" />
          )}
        </div>
      </div>
      {opened && (
        <div className="p-2 w-full border-t border-containerSecond-border text-sm whitespace-pre-line">
          {item.message}
        </div>
      )}
    </div>
  );
}

export default Messages;
