import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useFavorites } from "../../../context/FavoritesContext";
import { useConfig } from "../../../context/ConfigContext";
import CasinoGame from "../../Casino/CasinoGame";
import { useNavigate } from "react-router-dom";
import Requests from "../../../helpers/Requests";

function SidebarFavorites({ favoritesSidebarOpen, setFavoritesSidebarOpen }) {
  const FavoritesProvider = useFavorites();
  const ConfigProvider = useConfig();
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [closeTimeout, setCloseTimeout] = useState(null);
  const callCasinoGames = () => {
    if (!loading) {
      setLoading(true);
      const fetchData = async () => {
        const data = await Requests.postData("/casino/getFavoritesGames", {
          games: Object.keys(FavoritesProvider.casinoGames),
        });
        return data;
      };

      fetchData()
        .then((res) => {
          if (res.data.type === "success") {
            setData(res.data.data.data);
          } else {
            setData([]);
          }
          setLoading(false);
        })
        .catch((err) => {
          Requests.diagnostics(err, navigate);
          setData([]);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (favoritesSidebarOpen) {
      if (Object.keys(FavoritesProvider.casinoGames).length > 0) {
        callCasinoGames();
      }
    }
    // eslint-disable-next-line
  }, [favoritesSidebarOpen]);
  useEffect(() => {
    let spliceItems = [];
    data.forEach((item) => {
      if (!FavoritesProvider.casinoGames.hasOwnProperty(item.id)) {
        spliceItems.push(item.id);
      }
    });
    setData((oldData) => oldData.filter((x) => !spliceItems.includes(x.id)));
    // eslint-disable-next-line
  }, [FavoritesProvider.casinoGames]);
  return (
    <>
      <Transition.Root show={favoritesSidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => {
            closeTimeout && window.clearTimeout(closeTimeout);
            setCloseTimeout(
              setTimeout(() => {
                setFavoritesSidebarOpen(false);
              }, 150)
            );
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75 top-16" />
          </Transition.Child>

          <div className="fixed inset-y-0 z-50 flex right-0 max-w-md w-screen top-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full flex-1 flex-col text-page-text text-sm bg-page-bg pb-4">
                <div className="flex flex-shrink-0 items-center px-4 py-2 border-color-2 border-y">
                  <button
                    type="button"
                    className="h-8 w-8 focus:outline-none"
                    onClick={() => setFavoritesSidebarOpen(false)}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  {ConfigProvider.getTranslation("Favorites")}
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto px-2">
                  <nav className="flex flex-col">
                    <div className="space-y-1">
                      <div className="flex justify-center">
                        {data.length === 0 ? (
                          <div className="w-full p-2 text-lg flex flex-col items-center">
                            <XCircleIcon className="w-16 h-16 text-color-3 mb-2" />
                            {ConfigProvider.getTranslation("NoData")}
                          </div>
                        ) : (
                          <CasinoGame
                            data={data}
                            loading={loading}
                            miniCol={true}
                          />
                        )}
                      </div>
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default SidebarFavorites;
