import React from "react";
import ReactCountryFlag from "react-country-flag";

function Flag({ id, className }) {
  return (
    <ReactCountryFlag
      countryCode={id === "en" ? "gb" : id}
      svg
      className={"flex-shrink-0 rounded " + className}
    />
  );
}

export default Flag;
