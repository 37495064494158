import React, { useEffect, useRef, useState } from "react";
import Confetti from "react-confetti";
import jackpotTitle from "../../assets/img/jackpot/jackpotTitle.png";
import jackpotPattern from "../../assets/img/jackpot/pattern.jpg";
import "../../assets/css/jackpot.css";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useConfig } from "../../context/ConfigContext";
import CountUp from "react-countup";

function JackpotAnimation({ item, setShowJackpot }) {
  const ConfigProvider = useConfig();
  useEffect(() => {
    let timeout = setTimeout(() => {
      window.scrollTo(0, 0);
      document.documentElement.classList.add("noscrollbar");
      document.documentElement.style.overflow = "hidden";
      setGravity(0.1);

      return () => {
        document.documentElement.classList.remove("noscrollbar");
        document.documentElement.style.overflow = "auto";
      };
    }, 1000);
    return () => {
      window.clearTimeout(timeout);
    };
  }, []);

  const [width, setWidth] = useState(window.innerWidth - 10);
  const [height, setHeight] = useState(window.innerHeight);
  useEffect(() => {
    setWidth(window.innerWidth - 10);
    // eslint-disable-next-line
  }, [window.innerWidth]);
  useEffect(() => {
    setHeight(window.innerHeight);
    // eslint-disable-next-line
  }, [window.innerHeight]);
  const jackpotContainer = useRef(null);
  useEffect(() => {
    const timeout = setTimeout(() => {
      jackpotContainer.current.classList.add("scale-up-center");
      jackpotContainer.current.classList.remove("hidden");
    }, 1500);
    return () => {
      window.clearTimeout(timeout);
    };
  }, []);

  const bounceItem = useRef();
  useEffect(() => {
    let timeout = setTimeout(() => {
      bounceItem.current.classList.add("animate-bounce");
    }, 5000);
    return () => {
      window.clearTimeout(timeout);
    };
  }, []);
  const [gravity, setGravity] = useState(1);
  return (
    <div className="absolute top-0 left-0 w-full h-full bg-black/50 z-[1000] ">
      <div className="z-0 w-full h-full absolute">
        <Confetti gravity={gravity} width={width} height={height} />
      </div>
      <div className="flex w-full h-full items-center justify-center z-10">
        <div
          className="w-3/4 hidden max-w-[600px] min-h-[300px] p-5 rounded-lg relative"
          style={{
            boxShadow: "0 0 1px 1px #fd0",
            border: "10px inset #fc0",
            background: "url('" + jackpotPattern + "') 0% 0% no-repeat",
            backgroundSize: "cover",
          }}
          ref={jackpotContainer}
        >
          <img
            srcSet={jackpotTitle}
            className="w-64 absolute -top-[45%] left-0 right-0 mx-auto z-0"
            alt=""
          />

          <div className="w-full flex flex-col space-y-4 z-10">
            <div className="text-2xl md:text-4xl text-yellow-300 uppercase font-semibold text-center mt-[60px]">
              {ConfigProvider.getTranslation("Congratulations")}!
            </div>
            <div className="text-lg md:text-2xl text-white font-semibold text-center">
              {item.uname}
            </div>
            <div className="text-md md:text-lg text-white font-semibold text-center">
              {ConfigProvider.getTranslation("JackpotWonText")}!
            </div>
            <div
              ref={bounceItem}
              className="text-xl md:text-3xl text-yellow-300 font-semibold text-center"
            >
              <CountUp
                separator="."
                decimals={2}
                decimal=","
                delay={3}
                start={1}
                end={item.amount}
              />{" "}
              {item.currency}
            </div>

            <div className="text-center w-full">
              <div
                className="inline-flex rounded-full  items-center justify-center bg-red-500 cursor-pointer"
                onClick={() => setShowJackpot(false)}
              >
                <XMarkIcon className="w-10" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JackpotAnimation;
