export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const randomArray = (length) => {
  const array = [];
  for (let i = 0; i < length; i++) {
    array.push(Math.floor(Math.random() * 100));
  }
  return array;
};

export function formatDate(date, time = false) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  if (time) return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  else return `${year}-${month}-${day}`;
}
export function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function getDate() {
  let date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

export function BonusBalance(userSessionBonuses) {
  if (userSessionBonuses) {
    function toplamBalance() {
      return userSessionBonuses.reduce((total, item) => {
        return total + (item.balance || 0);
      }, 0);
    }
    return toplamBalance();
  }
  return "";
}
