import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useConfig } from "../../context/ConfigContext";
import ModalImage from "../../assets/img/modal.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
function Modal({ callback, children, showLogo = true, forSearch = false }) {
  const ConfigProvider = useConfig();
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  useEffect(() => {
    if (!open && callback && callback !== null) {
      callback();
    }
    // eslint-disable-next-line
  }, [open]);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`fixed inset-0 transition-opacity ${
              forSearch
                ? "bg-opacity-85 backdrop-blur-sm bg-black"
                : "bg-gray-500 bg-opacity-75"
            }`}
          />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div
            className={`flex min-h-full justify-center p-4 text-center ${
              !forSearch ? "items-center" : "items-start"
            } sm:p-0`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden ${
                  !forSearch
                    ? "rounded-lg bg-layout-bg shadow-xl sm:max-w-3xl"
                    : "sm:max-w-5xl"
                } text-left transition-all sm:my-8 w-full sm:mx-5`}
              >
                <div className={`grid ${showLogo ? "md:grid-cols-2" : ""}`}>
                  {showLogo && (
                    <div
                      id="loginModal"
                      className="hidden md:block bg-gradient-to-l from-home-from-2 to-home-to-2 relative"
                    >
                      <LazyLoadImage
                        alt={ConfigProvider.config.hostnames.name + " Logo"}
                        src={
                          process.env.REACT_APP_CDN_ENDPOINT +
                          ConfigProvider.config.hostnames.logo
                        }
                        className="h-12 absolute z-10 top-[50%] ml-20"
                      />
                      <img srcSet={ModalImage} alt="" className="h-full " />
                    </div>
                  )}
                  <div className="relative p-2">
                    {!forSearch && (
                      <div className="w-full flex items-center justify-end ">
                        <Link
                          ref={cancelButtonRef}
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <XMarkIcon className="w-6 h-6 text-page-text " />
                        </Link>
                      </div>
                    )}
                    {children}
                    {forSearch && (
                      <button
                        type="button"
                        ref={cancelButtonRef}
                        onClick={() => {
                          setOpen(false);
                        }}
                        className="my-4 flex items-center justify-center focus:outline-none text-sm sm:text-base rounded py-2 w-full transition duration-150 ease-in bg-home-from-3 font-bold"
                      >
                        <XMarkIcon className="w-6 h-6 mr-3 fill-current" />
                        <span>{ConfigProvider.getTranslation("Close")}</span>
                      </button>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Modal;
