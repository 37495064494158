import React, { Fragment, useState } from "react";
import { ReactComponent as WalletIcon } from "../../../assets/svg/wallet.svg";

import {
  ChevronDownIcon,
  ChevronRightIcon,
  GiftIcon,
  MagnifyingGlassIcon,
  UserIcon,
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  ClockIcon,
  UserPlusIcon,
  ArrowPathIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/solid";
import { BellIcon, StarIcon } from "@heroicons/react/24/outline";
import { useConfig } from "../../../context/ConfigContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { BonusBalance, classNames } from "../../../helpers/Helpers";
import { Link, NavLink } from "react-router-dom";
import { SvgLoader } from "react-svgmt";
import { Menu, Transition } from "@headlessui/react";
import { mobileStaticMenuArray } from "../../../helpers/StaticMenu";
import Confirm from "../Confirm";
import { useTimezone } from "../../../context/TimezoneContext";
import { useFavorites } from "../../../context/FavoritesContext";
import { ReactComponent as Headphones } from "../../../assets/svg/headphones.svg";
import { ReactComponent as DepositSVG } from "../../../assets/img/deposit.svg";
import { ReactComponent as WithDrawalSVG } from "../../../assets/img/withdrawal.svg";
import Login from "../../../pages/Login";
import Modal from "../Modal";
import SearchModal from "../SearchModal";

function Header({
  onlyLogo = false,
  setMobileMenuOpen,
  setFavoritesSidebarOpen,
}) {
  const ConfigProvider = useConfig();
  const TimezoneProvider = useTimezone();
  const FavoritesProvider = useFavorites();
  const conf = ConfigProvider.config;
  const mobileStaticMenu = mobileStaticMenuArray(ConfigProvider, [
    "/deposit",
    "/withdrawal",
    "/sendMoney",
  ]);
  const [signout, setSignOut] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [searchModal, setSearchModal] = useState(false);

  const [isClickedRefresh, setIsClickedRefresh] = useState(false);

  const checkUser = () => {
    ConfigProvider.checkUser();
    setIsClickedRefresh(true);
    var timeout = setTimeout(() => {
      setIsClickedRefresh(false);
    }, 1000);
    return () => {
      window.clearTimeout(timeout);
    };
  };

  return (
    <>
      <header className="border-b border-layout-border bg-layout-bg sticky top-0 w-full md:relative z-50">
        <div className="mx-auto md:container flex items-center justify-between h-16 md:h-[70px]">
          <div className="flex h-[70px] pl-4 grow items-center gap-x-4 md:hidden">
            <Link to="/">
              <LazyLoadImage
                alt={conf.hostnames.name + " Logo"}
                src={process.env.REACT_APP_CDN_ENDPOINT + conf.hostnames.logo}
                className="h-9"
              />
            </Link>
          </div>
          <div className="ml-1 hidden md:flex gap-x-4">
            {!onlyLogo ? (
              <div className="flex bg-page-bg rounded-xl p-1 justify-between items-center divide-x-2 divide-page-text/5 relative">
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    setSearchModal(true);
                  }}
                  className="px-3 lg:px-4 py-2 text-page-text cursor-pointer hover:text-white"
                >
                  <MagnifyingGlassIcon className="w-4 h-4 lg:w-5 lg:h-5 m-auto" />
                </div>
                <a
                  href="#top"
                  onClick={() => {
                    setMobileMenuOpen(false);
                    setTimeout(() => {
                      setFavoritesSidebarOpen(true);
                    }, 150);
                  }}
                  className="w-full text-page-text px-3 lg:px-4  py-2 hover:text-white"
                  role="button"
                >
                  <StarIcon className="w-4 h-4 lg:w-5 lg:h-5 m-auto" />
                  {Object.keys(FavoritesProvider.casinoGames).length > 0 && (
                    <span className="absolute top-0 animate-bounce-1 bg-green-400 text-black rounded-full px-1 ml-3 -mt-1 text-xs">
                      {Object.keys(FavoritesProvider.casinoGames).length}
                    </span>
                  )}
                </a>
                <div className="px-3 lg:px-4 py-2 text-page-text cursor-pointer hover:text-white">
                  <BellIcon className="w-4 h-4 lg:w-5 lg:h-5 m-auto" />
                </div>
              </div>
            ) : (
              <Link to="/">
                <LazyLoadImage
                  alt={conf.hostnames.name + " Logo"}
                  src={process.env.REACT_APP_CDN_ENDPOINT + conf.hostnames.logo}
                  className="h-9"
                />
              </Link>
            )}
          </div>
          {/* {conf.announcements && conf.announcements.length > 0 ? (
            <div className="grow px-5 hidden xl:block">
              <div className="flex items-center justify-center space-x-3">
                <div className="flex-grow-0">
                  <PaperAirplaneIcon className="w-7 h-7 text-page-text m-auto" />
                </div>
                <div className="max-w-[550px] rounded-lg px-2 w-full bg-page-bg text-page-text flex items-center">
                  <Marquee />
                </div>
              </div>
            </div>
          ) : null} */}
          {ConfigProvider.config.user !== null ? (
            <div className="hidden gap-2 md:flex">
              <div className="flex flex-col items-end justify-end">
                <div className="text-sm text-page-text">
                  {ConfigProvider.getTranslation("Balance")}
                </div>
                <div className="flex text-sm md:text-md lg:text-lg leading-none items-center space-x-2">
                  <Link onClick={checkUser} className="text-gradient-gold-b">
                    <ArrowPathIcon
                      className={`w-5 fill-current ${
                        isClickedRefresh ? "animate-spin" : ""
                      }`}
                    />
                  </Link>
                  <div className="grow">
                    {ConfigProvider.config.user.balance}{" "}
                    {ConfigProvider.config.user.currency}
                  </div>
                </div>
              </div>
              <Link
                to="/transactionHistory"
                className="px-3 h-11 rounded font-medium flex items-center bg-gradient-to-r from-gradient-gold-a to-gradient-gold-b text-sm text-page-bg"
              >
                <WalletIcon className="lg:mr-2.5 w-6 fill-page-bg" />
                <span className="hidden lg:inline">
                  {ConfigProvider.getTranslation("Wallet")}
                </span>
              </Link>
            </div>
          ) : null}

          <div className="flex gap-x-4 mr-2.5">
            <div className="hidden xl:flex items-center gap-2">
              <div
                className={
                  "flex items-center bg-page-bg font-medium text-page-text text-sm rounded-md px-2 py-2"
                }
              >
                <ClockIcon className="w-5 h-5 mr-1.5" />
                {TimezoneProvider.exportShortClock}
              </div>
            </div>
            {ConfigProvider.config.user && (
              <div className="hidden lg:flex items-center gap-2 relative">
                <Link
                  to="/messages"
                  className={
                    "flex items-center bg-page-bg font-medium text-page-text text-sm rounded-md px-2 py-2"
                  }
                >
                  <EnvelopeIcon className="w-5 h-5" />
                  {ConfigProvider.config.user.unreadMessages > 0 && (
                    <span className="absolute top-1 -right-1 animate-bounce-1 bg-green-400 text-black rounded-full px-1 ml-3 text-[9px]">
                      {ConfigProvider.config.user.unreadMessages}
                    </span>
                  )}
                </Link>
              </div>
            )}
            {ConfigProvider.config.user &&
              ConfigProvider.config.user.userSessionBonuses.length > 0 && (
                <div className="flex items-center gap-2">
                  <Link
                    className="flex items-center font-medium text-sm rounded-md p-2 relative lg:bg-left-title-bg lg:text-left-title-text"
                    to="/bonuses"
                  >
                    <GiftIcon className="w-5 h-5 lg:mr-1.5" />
                    <span className="hidden lg:inline">
                      {`${BonusBalance(
                        ConfigProvider.config.user.userSessionBonuses
                      )} `}
                      <span className="text-xs">
                        {ConfigProvider.config.user.currency}
                      </span>
                    </span>
                    <div className="text-[9.5px] animate-bounce lg:hidden leading-none px-1.5 pt-1 pb-0.5 bg-left-title-bg text-left-title-text font-semibold rounded-full absolute right-0 top-0 -mr-1">
                      {ConfigProvider.config.user.userSessionBonuses.length}
                    </div>
                  </Link>
                </div>
              )}
            {ConfigProvider.config.user !== null ? (
              <>
                <Menu as="div" className="relative">
                  <div>
                    <Menu.Button className="relative flex items-center rounded-md px-2 py-2 font-semibold text-xs text-color-3">
                      <div className="flex gap-2.5">
                        <div className="flex flex-col items-end justify-center">
                          <div className="">
                            <span className="text-sm ">
                              {ConfigProvider.config.user.uname}
                            </span>
                          </div>
                        </div>
                        <div className="rounded hidden lg:inline bg-[#212631] p-2 text-page-text">
                          <UserIcon className="w-6 h-6" />
                        </div>
                        <div className="flex items-center">
                          <div className="rounded ring-offset-background bg-background p-0.5 text-page-text flex items-center">
                            <ChevronDownIcon className="w-4 h-4" />
                          </div>
                        </div>
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 w-56 z-50 overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-lg text-xs">
                      <Menu.Item>
                        <div className="flex items-center lg:hidden bg-layout-bg text-page-text px-4 py-1">
                          <div className="grow flex flex-col">
                            <div className="text-xs">
                              {ConfigProvider.getTranslation("BonusBalance")}:
                            </div>
                            <div className="text-sm">
                              {`${BonusBalance(
                                ConfigProvider.config.user.userSessionBonuses
                              )} `}
                              <span className="text-xs">
                                {ConfigProvider.config.user.currency}
                              </span>
                            </div>
                          </div>
                          <GiftIcon className="w-5 h-5 mr-2 fill-[#94a3b8]" />
                        </div>
                      </Menu.Item>
                      {mobileStaticMenu.map((item, index) => (
                        <Menu.Item key={index}>
                          {({ active }) => (
                            <NavLink
                              to={item.link}
                              className={({ isActive }) =>
                                classNames(
                                  active
                                    ? "bg-popover-foreground text-white"
                                    : isActive
                                    ? "bg-layout-bg text-white"
                                    : "",
                                  "flex items-center px-4 py-2 text-page-text"
                                )
                              }
                            >
                              {item.icon && (
                                <SvgLoader
                                  svgXML={item.icon}
                                  className="w-5 h-5 mr-2 fill-[#94a3b8]"
                                ></SvgLoader>
                              )}
                              <div className="truncate">{item.name}</div>
                            </NavLink>
                          )}
                        </Menu.Item>
                      ))}
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={() => {
                              setSignOut(true);
                            }}
                            className={classNames(
                              active ? "bg-popover-foreground text-white" : "",
                              "flex items-center px-4 py-2 text-page-text"
                            )}
                          >
                            <ArrowRightOnRectangleIcon className="w-5 h-5 mr-2 fill-[#94a3b8]" />
                            <div className="truncate">
                              {ConfigProvider.getTranslation("SignOut")}
                            </div>
                          </Link>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>

                {signout ? (
                  <Confirm
                    title={ConfigProvider.getTranslation("AreYouSure")}
                    text={ConfigProvider.getTranslation("Logs.Logout")}
                    confirm={ConfigProvider.signout}
                    callback={() => {
                      setSignOut(false);
                    }}
                  />
                ) : null}
              </>
            ) : (
              <>
                <Link
                  to="/login"
                  onClick={(e) => {
                    e.preventDefault();
                    setLoginModal(true);
                  }}
                  className="px-3 h-11 rounded font-bold flex items-center text-white/90"
                >
                  {ConfigProvider.getTranslation("Login")}
                </Link>
                <Link
                  to="/registerFull"
                  className="px-3 h-11 rounded-xl font-bold flex items-center bg-gradient-to-r from-gradient-gold-a to-gradient-gold-b text-sm text-page-bg"
                >
                  <UserPlusIcon className="mr-2.5 w-6 fill-page-bg" />
                  {ConfigProvider.getTranslation("Register")}
                </Link>
              </>
            )}
          </div>
        </div>
      </header>

      {loginModal && (
        <Modal
          callback={() => {
            setLoginModal(false);
          }}
        >
          <Login
            callback={() => {
              setLoginModal(false);
            }}
          />
        </Modal>
      )}
      {searchModal && (
        <Modal
          showLogo={false}
          callback={() => {
            setSearchModal(false);
          }}
          forSearch={true}
        >
          <SearchModal
            callback={() => {
              setSearchModal(false);
            }}
          />
        </Modal>
      )}
    </>
  );
}

function HeaderLeftSide() {
  const ConfigProvider = useConfig();
  const conf = ConfigProvider.config;
  const [onlyIcon, setOnlyIcon] = useState(false);
  return (
    <nav
      className={classNames(
        "hidden shrink-0 border-r border-layout-border bg-layout-bg md:block",
        onlyIcon ? "w-[90px]" : "w-[250px]"
      )}
    >
      <div
        className={classNames("fixed ", onlyIcon ? "w-[90px]" : "w-[250px]")}
      >
        <div className="h-screen overflow-y-auto ">
          <div className="mb-4 flex h-[70px] items-center justify-center border-b border-layout-border bg-layout-bg gap-x-4 px-2">
            <div
              className="p-2 bg-menu-bg rounded-sm  text-left-title-text hover:text-left-title-text/75 cursor-pointer ring-page-bg ring-1"
              onClick={() => {
                setOnlyIcon(!onlyIcon);
              }}
            >
              <Bars3Icon className="w-6 h-6" />
            </div>
            {!onlyIcon && (
              <Link className="grow" to="/">
                <LazyLoadImage
                  alt={conf.hostnames.name + " Logo"}
                  src={process.env.REACT_APP_CDN_ENDPOINT + conf.hostnames.logo}
                  className="h-6 sm:h-9"
                />
              </Link>
            )}
          </div>
          <div className="w-full">
            <Menus onlyIcon={onlyIcon} />
            <div className="mx-[15px] mb-1">
              <Link to="/promotions" className="group mb-1 w-full">
                <div className="bg-left-title-bg flex h-12 w-full items-center justify-between rounded-md p-1">
                  <div className="flex">
                    <GiftIcon className="mx-4 w-5 h-5 text-left-title-text" />
                    {!onlyIcon && (
                      <div className="text-sm font-bold text-left-title-text">
                        {ConfigProvider.getTranslation("Promotions")}
                      </div>
                    )}
                  </div>
                </div>
              </Link>
            </div>

            <hr className="border-0 border-b my-5" />

            {conf.user === null ? (
              <div className="w-full">
                <div className="mx-[15px] mb-2">
                  <Link to="/login">
                    <div className="w-full bg-gradient-to-r from-home-from-3 to-home-to-3 py-3 rounded text-white/90 hover:brightness-95 flex items-center justify-center gap-x-2">
                      <ArrowRightOnRectangleIcon className="w-5 h-5" />{" "}
                      {!onlyIcon && (
                        <div className="text-sm font-bold">
                          {ConfigProvider.getTranslation("LoginButton")}
                        </div>
                      )}
                    </div>
                  </Link>
                </div>
                <div className="mx-[15px]">
                  <Link to="/registerFull">
                    <div className="w-full bg-gradient-to-r from-gradient-gold-a to-gradient-gold-b py-3 rounded hover:brightness-95 flex items-center justify-center gap-x-2  text-page-bg">
                      <UserPlusIcon className="w-5 h-5" />{" "}
                      {!onlyIcon && (
                        <div className="text-sm font-bold">
                          {ConfigProvider.getTranslation("Register")}
                        </div>
                      )}
                    </div>
                  </Link>
                </div>
              </div>
            ) : (
              <div className="w-full px-[15px]">
                <div
                  className={classNames(
                    "grid gap-2",
                    onlyIcon ? "" : "grid-cols-2"
                  )}
                >
                  <div className="mb-2 w-full h-full">
                    <Link to="/deposit">
                      <div className="w-full h-full bg-gradient-to-b  from-gradient-gold-a to-gradient-gold-b py-3 rounded hover:brightness-105 flex flex-col items-center justify-center text-page-bg">
                        <DepositSVG className="w-5 h-5 fill-page-bg" />{" "}
                        {!onlyIcon && (
                          <div className="text-xs font-bold mt-2">
                            {ConfigProvider.getTranslation("Deposit")}
                          </div>
                        )}
                      </div>
                    </Link>
                  </div>
                  <div className="mb-2 w-full h-full">
                    <Link to="/withdrawal">
                      <div className="w-full h-full bg-gradient-to-b  from-home-from-4 to-home-to-4 py-3 rounded hover:brightness-105 flex flex-col items-center justify-center">
                        <WithDrawalSVG className="w-5 h-5 fill-black/70" />{" "}
                        {!onlyIcon && (
                          <div className="text-xs font-bold text-black/70 mt-2">
                            {ConfigProvider.getTranslation("Withdrawal")}
                          </div>
                        )}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            )}
            <div className="mx-[15px] mt-5">
              <Link
                onClick={() => {
                  try {
                    // eslint-disable-next-line
                    //eval('$crisp.push(["do", "chat:open"])');
                    // eval("Comm100API.open_chat_window()");

                    // eslint-disable-next-line
                    eval(ConfigProvider.config.cms.liveSupportOpenWindow);
                  } catch (e) {}
                }}
                className="group mb-1 w-full"
              >
                <div className="w-full bg-gradient-to-r bg-left-title-bg py-3 rounded text-left-title-text hover:brightness-95 flex items-center gap-x-2">
                  <div className="flex">
                    <Headphones className="mx-4 w-5 h-5 fill-current" />
                    {!onlyIcon && (
                      <div className="text-sm font-bold">
                        {ConfigProvider.getTranslation("LiveSupport")}
                      </div>
                    )}
                  </div>
                </div>
              </Link>
            </div>
            <div className="mx-[15px] mt-5">
              <SocialMedias onlyIcon={onlyIcon} />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
function SocialMedias({ onlyIcon }) {
  const ConfigProvider = useConfig();

  return ConfigProvider.config &&
    ConfigProvider.config.socialMedia &&
    ConfigProvider.config.socialMedia.length > 0 ? (
    <div
      className={`grid gap-2 items-center justify-center ${
        onlyIcon ? "" : "grid-cols-4"
      }`}
    >
      {ConfigProvider.config.socialMedia.map((item, index) => (
        <Link
          target="_blank"
          to={item.link}
          key={index}
          className="border rounded-full py-1.5 border-left-title-bg text-left-title-bg hover:bg-left-title-bg hover:text-left-title-text"
          title={item.name}
        >
          <SvgLoader
            svgXML={item.icon}
            className="mx-3 w-5 h-5 fill-current"
          ></SvgLoader>
        </Link>
      ))}
    </div>
  ) : null;
}
function Menus({ onlyIcon }) {
  const ConfigProvider = useConfig();
  const user = ConfigProvider.config.user;
  const menus = ConfigProvider.config.menus.hasOwnProperty("header")
    ? ConfigProvider.config.menus["header"]
    : [];
  const [showParent, setShowParent] = useState(
    menus.length > 0 ? menus[0]._id : ""
  );
  return menus
    .filter(
      (x) =>
        x.parent === "000000000000000000000000" &&
        (x.type === 0) |
          (x.type === 1 && user != null) |
          (x.type === 2 && user === null)
    )
    .map((item, index) => {
      const subItems = menus.filter((x) => x.parent === item._id);

      return (
        <div className="mx-[15px] mb-1" key={index}>
          <NavLink
            to={item.link.length > 0 ? item.link : undefined}
            onClick={() => {
              subItems.length > 0 &&
                setShowParent((prev) => (prev === item._id ? "" : item._id));
            }}
            target={item.target}
            className={({ isActive, isPending }) =>
              classNames(
                "group mb-1 w-full",
                isActive || isPending ? "font-medium" : ""
              )
            }
          >
            <div className="bg-left-title-bg text-left-title-text flex h-12 w-full items-center justify-between rounded-md p-1">
              <div className={classNames("flex", item.icon ? "" : "pl-11")}>
                {item.icon && (
                  <SvgLoader
                    svgXML={item.icon}
                    className="mx-4 w-5 h-5 fill-current"
                  ></SvgLoader>
                )}
                {!onlyIcon && (
                  <div className="text-sm font-bold">
                    {ConfigProvider.translateItem(item)}
                  </div>
                )}
              </div>
              {!onlyIcon && subItems.length > 0 && (
                <ChevronRightIcon
                  className={classNames(
                    "h-[20px] justify-self-end stroke-page-text transition duration-500",
                    showParent === item._id ? "rotate-90 " : "rotate-0"
                  )}
                />
              )}
            </div>
          </NavLink>
          {subItems.length > 0 && showParent === item._id && (
            <div className="">
              {subItems.map((subItem, i) => (
                <RenderSubItem key={i} item={subItem} onlyIcon={onlyIcon} />
              ))}
            </div>
          )}
        </div>
      );
    });
}

function RenderSubItem({ item, onlyIcon }) {
  const ConfigProvider = useConfig();
  return (
    <NavLink
      to={item.link}
      className={({ isActive, isPending }) =>
        classNames(
          "group flex bg-none p-4 text-sm hover:text-page-text items-center hover:brightness-125 font-bold ",
          isActive || isPending ? "" : "",
          item.icon ? "" : "pl-11",
          onlyIcon ? "justify-center m-0 p-0" : ""
        )
      }
    >
      {item.icon && (
        <SvgLoader
          svgXML={item.icon}
          className={classNames(
            "w-3.5 h-3.5 fill-current",
            onlyIcon ? "" : "mr-2.5 "
          )}
        ></SvgLoader>
      )}

      {!onlyIcon && ConfigProvider.translateItem(item)}
    </NavLink>
  );
}
export { Header, HeaderLeftSide };
