import React, { useEffect, useState } from "react";
import Requests from "../helpers/Requests";
import { useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useConfig } from "../context/ConfigContext";

function Promotion({ callback, _id }) {
  const ConfigProvider = useConfig();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await Requests.postData("/general/getPromotion", {
        _id: _id,
      });
      return data;
    };

    fetchData()
      .then((response) => {
        const res = response.data;
        if (res.type === "success") {
          setData(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        Requests.diagnostics(err, navigate);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col">
      <h3 className="text-center font-semibold text-xl my-2 text-gradient-gold-b">
        {loading ? (
          <div className="bg-layout-bg rounded w-full animate-pulse brightness-125">
            &nbsp;
          </div>
        ) : (
          data.title
        )}
      </h3>
      <div className="w-full my-2">
        {loading ? (
          <div className="bg-layout-bg rounded aspect-video animate-pulse brightness-125">
            &nbsp;
          </div>
        ) : (
          <img
            srcSet={
              process.env.REACT_APP_CDN_ENDPOINT + "/upload/img/" + data.image
            }
            alt=""
            className="w-full"
          />
        )}
      </div>
      {loading ? (
        <div className="w-full">
          <div className="bg-layout-bg rounded w-full animate-pulse brightness-125 mb-2">
            &nbsp;
          </div>
          <div className="bg-layout-bg rounded w-full animate-pulse brightness-125 mb-2">
            &nbsp;
          </div>
          <div className="bg-layout-bg rounded w-full animate-pulse brightness-125">
            &nbsp;
          </div>
        </div>
      ) : (
        <div
          className="p-3 w-full overflow-y-scroll max-h-80"
          dangerouslySetInnerHTML={{ __html: data.detail }}
        />
      )}
      <div className="flex w-full mt-2 min-w-[300px]">
        <button
          type="button"
          onClick={() => {
            callback();
          }}
          className="flex items-center justify-center focus:outline-none text-sm sm:text-base rounded py-2 w-full transition duration-150 ease-in bg-home-from-3 font-bold"
        >
          <XMarkIcon className="w-4 h-4 mr-2 shrink-0" />
          <span>{ConfigProvider.getTranslation("Close")}</span>
        </button>
      </div>
    </div>
  );
}

export default Promotion;
