import React, { useEffect, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import { useNavigate } from "react-router-dom";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "../components/Common/Loading/LoadingSpinner";
import Alert from "../components/Common/Alert";
import Requests from "../helpers/Requests";
import Confirm from "../components/Common/Confirm";
import SelfExclusionRadio from "../components/Common/FormElements/SelfExclusionRadio";

function Limits() {
  const ConfigProvider = useConfig();
  const navigate = useNavigate();
  useEffect(() => {
    if (ConfigProvider.config.user === null) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div className="flex justify-center py-3 md:py-10 px-3">
      <div className="flex flex-col w-full lg:w-2/3 max-w-screen-md">
        <div className="self-center text-xl sm:text-2xl font-bold">
          {ConfigProvider.getTranslation("Limits")}
        </div>
        <div className="mt-3 md:mt-10">
          <SelfExclusionForm />
        </div>
      </div>
    </div>
  );
}
function SelfExclusionForm() {
  const ConfigProvider = useConfig();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ val: null });
  const [messages, setMessages] = useState([]);
  const updateForm = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      let requiredFieldErros = [];

      if (requiredFieldErros.length > 0) {
        setMessages([
          {
            type: "error",
            text:
              "[" +
              requiredFieldErros.join(",") +
              "] " +
              ConfigProvider.getTranslation("Forms.EmptyValueError"),
          },
        ]);
        window.scrollTo(0, 0);
        setLoading(false);
      } else {
        Requests.postData("/user/selfExclusion", formData)
          .then((response) => {
            let res = response.data;
            if (res.type !== "success") {
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
              window.scrollTo(0, 0);
            } else {
              setMessages([
                {
                  type: "success",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.SuccessMessage"),
                },
              ]);
              setFormData({
                val: null,
              });
              setTimeout(() => {
                ConfigProvider.signout();
              }, 2000);
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };
  const [showConfirm, setShowConfirm] = useState(false);
  return (
    <>
      <form
        className="flex-none w-full"
        onSubmit={(e) => {
          e.preventDefault();
          setShowConfirm(true);
        }}
      >
        <div className="mt-2 mb-4">
          <Alert messages={messages} setMessages={setMessages} />
        </div>
        <div className="w-full p-4 text-page-text">
          <div className="w-full text-center text-lg py-2 border-b border-color-2 mb-5 font-bold text-page-text">
            {ConfigProvider.getTranslation("SelfExclusion")}
          </div>
          <div className="text-sm mt-2 mb-6">
            {ConfigProvider.getTranslation(
              "ThisFeatureAllowsYouToExcludeYourselfFromYourSpecialGamingAccountOnThisWebsiteForACertainPeriod.ByUsingTheSelf-ExclusionFeature,YouCannotAccessYourSpecialGamingAccountOrEngageInGamingActivityForTheDurationYouSpecify(From6MonthsTo5Years).However,UsingThisFeatureWillNotDepriveYouOfTheOpportunityToWithdrawMoneyFromYourSpecialGamingAccount"
            )}
          </div>
          <div className="flex flex-col mb-6">
            <label
              htmlFor="val"
              className="mb-1 text-xs sm:text-sm tracking-wide"
            >
              {ConfigProvider.getTranslation("Period")}:
            </label>
            <div className="relative">
              <SelfExclusionRadio
                onUpdate={(value) => {
                  setFormData({ ...formData, val: value });
                }}
              />
            </div>
          </div>

          <div className="flex flex-col">
            <button
              type="submit"
              className="flex items-center justify-center focus:outline-none text-sm sm:text-base rounded py-2 w-full transition duration-150 ease-in bg-home-from-8 text-page-bg font-bold"
              disabled={loading}
            >
              <span className="mr-2 uppercase">
                {ConfigProvider.getTranslation("ExcludeAccount")}
              </span>
              {loading ? (
                <span>
                  <LoadingSpinner className="w-6 h-6" />
                </span>
              ) : (
                <span>
                  <ArrowRightCircleIcon className="w-6 h-6" />
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
      {showConfirm ? (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={ConfigProvider.getTranslation("YourAccountWillBeExcluded")}
          confirm={updateForm}
          callback={() => {
            setShowConfirm(false);
          }}
        />
      ) : null}
    </>
  );
}
export default Limits;
