import React, { useEffect, useState } from "react";
import JackpotText from "../../assets/img/jackpottext.svg";
import { CubeTransparentIcon } from "@heroicons/react/24/solid";
import Requests from "../../helpers/Requests";
import { useNavigate } from "react-router-dom";
import CountUp from "react-countup";
import { useSocket } from "../../context/SocketContext";

function Jackpot({ type }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [global, setGlobal] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    callJackpots();
    // eslint-disable-next-line
  }, []);
  const callJackpots = () => {
    const fetchData = async () => {
      const data = await Requests.postData("/general/getJackpots", {
        type: type,
      });
      return data;
    };

    fetchData()
      .then((res) => {
        if (res.data.type === "success") {
          setData(
            res.data.data.filter((x) => {
              return !x.global;
            })
          );
          setGlobal(
            res.data.data.filter((x) => {
              return x.global;
            })
          );
          setSubscribeIds(res.data.data.map((x) => x._id));
        }
        setLoading(false);
      })
      .catch((err) => {
        Requests.diagnostics(err, navigate);
        setLoading(false);
      });
  };

  const [subscribeIds, setSubscribeIds] = useState([]);
  const SocketProvider = useSocket();
  useEffect(() => {
    return () => {
      subscribeIds.forEach((element) => {
        SocketProvider.unsubscribe(["jackpot-" + element]);
      });
    };
    // eslint-disable-next-line
  }, [subscribeIds]);
  useEffect(() => {
    if (SocketProvider.connected) {
      subscribeIds.forEach((element) => {
        SocketProvider.subscribe(["jackpot-" + element]);
      });
    }
    // eslint-disable-next-line
  }, [SocketProvider.connected, subscribeIds]);

  const { jackpotQueue, clearJackpotMessages } = SocketProvider;

  useEffect(() => {
    if (jackpotQueue.length > 0) {
      const readMessages = jackpotQueue;
      readMessages.forEach((item) => {
        const message = JSON.parse(item.message);

        if (global.find((x) => x._id === message._id)) {
          setGlobal((prev) => {
            const updatedArray = prev.map((item) =>
              item._id === message._id
                ? {
                    ...item,
                    balance: message.balance,
                    oldBalance: item.balance,
                  }
                : item
            );
            return updatedArray;
          });
        }
        if (data.find((x) => x._id === message._id)) {
          setData((prev) => {
            const updatedArray = prev.map((item) =>
              item._id === message._id
                ? {
                    ...item,
                    balance: message.balance,
                    oldBalance: item.balance,
                  }
                : item
            );
            return updatedArray;
          });
        }
      });
      clearJackpotMessages(readMessages.length);
    }
    // eslint-disable-next-line
  }, [jackpotQueue, clearJackpotMessages]);
  return (
    <>
      {loading | (data.length === 0 && global.length === 0) ? (
        // <div className={(loading ? "animate-pulse " : "") + "w-full"}>
        //   <div className="bg-layout-bg rounded flex items-center justify-center w-full aspect-[403/222] lg:aspect-[883/170]">
        //     <img
        //       srcSet={JackpotText}
        //       alt="Jackpot"
        //       className="h-[20px] lg:h-[35px] grayscale"
        //     />
        //   </div>
        // </div>
        <></>
      ) : (
        <div className="flex flex-col py-10 jackpotContainer w-full h-full justify-center rounded">
          {global.map((item, key) => (
            <div key={key}>
              <div className="w-full items-center justify-center ">
                <div className="flex w-full items-center justify-center text-xl theme-text-color font-bold uppercase">
                  {item.name}
                </div>

                <div className="flex w-full items-center justify-center mt-1">
                  <img
                    srcSet={JackpotText}
                    alt="Jackpot"
                    className="h-[20px] lg:h-[35px]"
                  />
                </div>
              </div>
              <div className="my-5 flex w-full justify-center text-4xl">
                <span className="mr-2 font-bold">
                  <CountUp
                    separator="."
                    decimals={2}
                    decimal=","
                    start={item.oldBalance ?? 1}
                    end={item.balance}
                  />
                </span>
                <span className="text-lg">{item.currency}</span>
              </div>
            </div>
          ))}
          {global.length === 0 ? (
            <div>
              <div className="w-full items-center justify-center ">
                <div className="flex w-full items-center justify-center py-5">
                  <img
                    srcSet={JackpotText}
                    alt="Jackpot"
                    className="h-[20px] lg:h-[35px]"
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="my-5 grid grid-cols-1 gap-2 w-full px-2 md:grid-cols-2">
            {data.map((item, key) => (
              <div key={key} className="bg-slate-900/30 w-full rounded-md">
                <div className="flex w-full px-2">
                  <div className="flex items-center justify-center">
                    <CubeTransparentIcon className="w-6 h-6" />
                  </div>
                  <div className="grow justify-end pr-1 py-2">
                    <div className="flex justify-end text-sm font-bold theme-text-color uppercase">
                      {item.name}
                    </div>
                    <div className="flex justify-end mt-1">
                      <div className="mr-1 text-md">
                        <CountUp
                          separator="."
                          decimals={2}
                          decimal=","
                          start={item.oldBalance ?? 1}
                          end={item.balance}
                        />
                      </div>
                      <div className="text-[9px]">{item.currency}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default Jackpot;
