import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Requests from "../../helpers/Requests";
import CasinoGame from "./CasinoGame";
import { useConfig } from "../../context/ConfigContext";
import { classNames } from "../../helpers/Helpers";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { SparklesIcon } from "@heroicons/react/24/outline";

function CasinoTopGames({ path, showSearch }) {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [tab, setTab] = useState("");
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  const [timeoutId, setTimeoutId] = useState(null);
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(
      () => {
        callCasinoTopGames();
      },
      search.length ? 250 : 0
    );
    setTimeoutId(newTimeoutId);
    // eslint-disable-next-line
  }, [search, path]);

  const callCasinoTopGames = () => {
    if (!loading) {
      setLoading(true);
      const fetchData = async () => {
        const data = await Requests.postData("/casino/getCategoriesWithGames", {
          path: path,
          search: search,
        });
        return data;
      };
      fetchData()
        .then((res) => {
          if (res.data.type === "success") {
            if (tab === "" && res.data.data.length > 0) {
              setTab(res.data.data[0].id);
            }
            setData(res.data.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          Requests.diagnostics(err, navigate);
          setLoading(false);
        });
    }
  };
  const ConfigProvider = useConfig();
  return (
    <>
      {!showSearch && data && data.length === 1 ? (
        <div className="py-5 px-1 mb-2">
          <div className="text-xl md:text-2xl items-center flex space-x-2 font-semibold">
            <div>
              <SparklesIcon className="w-8 h-8 text-menu-bg" />
            </div>
            <div>{ConfigProvider.getTranslation("PopularGames")}</div>
          </div>
        </div>
      ) : (
        <div className="py-5">
          <div className="flex flex-col md:flex-row bg-layout-bg rounded-md w-full text-sm md:space-x-5">
            <div className="grow flex">
              <ul
                className={`grow md:px-2 truncate ${
                  data && data.length === 0 ? "" : "h-[55px]"
                }`}
              >
                {loading | (data === null)
                  ? [1, 2, 3, 4, 5, 6].map((item, index) => (
                      <li
                        key={index}
                        className="cursor-pointer py-2.5 px-1 float-left"
                      >
                        <div className="bg-page-bg rounded animate-pulse w-24 h-8">
                          &nbsp;
                        </div>
                      </li>
                    ))
                  : data.map((item, index) => (
                      <li
                        key={index}
                        className="cursor-pointer py-2.5 px-1 float-left"
                        onClick={() => {
                          setTab(item.id);
                        }}
                      >
                        <div
                          className={classNames(
                            "text-page-text text-sm px-6 py-2 font-semibold rounded-md cursor-pointer hover:bg-layout-border hover:text-white",
                            tab === item.id ? "bg-layout-border text-white" : ""
                          )}
                        >
                          {ConfigProvider.translateItem(item)}
                        </div>
                      </li>
                    ))}
              </ul>
            </div>
            {showSearch && (
              <div className="p-2">
                <Search search={search} setSearch={setSearch} />
              </div>
            )}
          </div>
        </div>
      )}

      {tab !== "" && data !== null && data.find((x) => x.id === tab) ? (
        <div className="flex w-full">
          <CasinoGame
            data={data.find((x) => x.id === tab).games}
            loading={loading}
          />
        </div>
      ) : null}
    </>
  );
}
function Search({ search, setSearch }) {
  const ConfigProvider = useConfig();
  return (
    <div className="flex items-center justify-center w-full">
      <div className="bg-page-bg rounded-md flex w-full">
        <button className="w-8 ml-1 flex items-center justify-center">
          <MagnifyingGlassIcon className="w-5 h-5 text-page-text" />
        </button>
        <input
          type="text"
          placeholder={ConfigProvider.getTranslation("Search") + ".."}
          className="bg-page-bg text-page-text placeholder:text-page-text rounded-md border-none outline-none w-full text-sm px-2 py-2"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    </div>
  );
}

export default CasinoTopGames;
