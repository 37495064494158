import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useConfig } from "../../context/ConfigContext";
import { PlayIcon, StarIcon } from "@heroicons/react/24/solid";
import { useFavorites } from "../../context/FavoritesContext";
import { Link } from "react-router-dom";
import Login from "../../pages/Login";
import Modal from "../Common/Modal";

function CasinoGame({ loading, data, miniCol = false }) {
  const ConfigProvider = useConfig();
  const [loginModal, setLoginModal] = useState(false);
  const [showPlayIcon, setShowPlayIcon] = useState("");
  const FavoritesProvider = useFavorites();
  const loadingArray = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24,
  ];
  const cdnEndpoint = process.env.REACT_APP_CDN_ENDPOINT;
  return (
    <>
      <div className="w-full text-center pb-4">
        <div
          className={
            "grid grid-cols-2 gap-4 " +
            (miniCol ? "" : "md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5")
          }
        >
          {loading ? (
            <>
              {(data.length === 0 ? loadingArray : data).map((item, index) => (
                <div key={index} className="w-full">
                  <div className="aspect-[16/11] bg-layout-bg animate-pulse rounded"></div>
                  <div className="truncate px-4 py-2 animate-pulse h-6"></div>
                </div>
              ))}
            </>
          ) : (
            <>
              {data.map((item, index) => (
                <div key={index} className="w-full">
                  <div
                    className="group transition duration-1000 rounded-t-lg overflow-hidden bg-layout-bg w-full relative"
                    onClick={() => {
                      setShowPlayIcon(item.id);
                    }}
                    onMouseEnter={() => {
                      setShowPlayIcon(item.id);
                    }}
                    onMouseLeave={() => {
                      setShowPlayIcon("");
                    }}
                  >
                    {showPlayIcon === item.id && (
                      <div className="backdrop-blur-sm absolute w-full h-full flex flex-col duration-200 translation z-10">
                        <div className="flex-none text-sm p-2 w-full">
                          {FavoritesProvider.casinoGames.hasOwnProperty(
                            item.id
                          ) ? (
                            <StarIcon
                              className="w-4 h-4 cursor-pointer text-gradient-gold-a"
                              onClick={() => {
                                FavoritesProvider.toggleCasinoGames(item.id);
                              }}
                            />
                          ) : (
                            <StarIcon
                              className="w-4 h-4 cursor-pointer text-white hover:text-gradient-gold-a"
                              onClick={() =>
                                FavoritesProvider.toggleCasinoGames(item.id)
                              }
                            />
                          )}
                        </div>
                        <div className="flex flex-1 flex-col space-y-2 items-center justify-center ">
                          <div className="flex">
                            <Link
                              onClick={(e) =>
                                ConfigProvider.config.user === null
                                  ? setLoginModal(true) && e.preventDefault()
                                  : null
                              }
                              to={
                                ConfigProvider.config.user !== null
                                  ? "/casinoGame/" +
                                    item.id +
                                    (item.fullScreen ? "/full" : "")
                                  : ""
                              }
                              className="inline-flex transition w-auto rounded-lg bg-gradient-to-t from-gradient-gold-a to-gradient-gold-b font-semibold text-xs items-center cursor-pointer px-4 py-2 text-white "
                            >
                              <PlayIcon className="w-6 h-6 mr-1" /> Play
                            </Link>
                          </div>
                          {(item.demoUrl !== "") | (item.hasDemo === "1") ? (
                            <div className="flex">
                              <Link
                                to={
                                  "/casinoGame/" +
                                  item.id +
                                  (item.fullScreen ? "/full" : "") +
                                  "?demo=1"
                                }
                                className="inline-flex transition w-auto rounded-lg  bg-home-from-8 from-home-from-8 to-home-to-8 font-semibold text-xs items-center cursor-pointer px-4 py-2 text-white "
                              >
                                Demo
                              </Link>
                            </div>
                          ) : null}
                        </div>
                        <div className="truncate px-4 pt-2 text-sm">&nbsp;</div>
                      </div>
                    )}
                    <div className="w-full relative">
                      {item.jackpot ? (
                        <div className="bg-jackpot"></div>
                      ) : item.freespin ? (
                        <div className="bg-freespin"></div>
                      ) : null}
                      <LazyLoadImage
                        src={
                          item.imageUrl.startsWith("casino/")
                            ? `${cdnEndpoint}/upload/img/${item.imageUrl}`
                            : item.imageUrl
                        }
                        className={
                          "w-full aspect-[16/11] transition " +
                          (showPlayIcon === item.id ? "scale-150" : "")
                        }
                      />
                    </div>
                  </div>
                  <div className="bg-layout-bg rounded-b-lg mt-2 text-color-1 px-4 py-2 text-sm font-semibold">
                    <div className="w-full truncate">{item.displayName}</div>
                    <div className="clear-none w-full h-1"></div>
                    <div className="w-full text-xs text-menu-bg truncate">
                      {item.providerName}
                    </div>
                  </div>
                </div>
              ))}

              {loginModal && (
                <Modal
                  callback={() => {
                    setLoginModal(false);
                  }}
                >
                  <Login
                    callback={() => {
                      setLoginModal(false);
                    }}
                  />
                </Modal>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CasinoGame;
