import React, { createContext, useContext, useEffect, useState } from "react";

const FavoritesContext = createContext();

export function FavoritesProvider({ children }) {
  const [casinoGames, setCasinoGames] = useState({});
  const [lastPlayed, setLastPlayed] = useState({});
  const [events, setEvents] = useState({}); // for live
  const [marketTypes, setMarketTypes] = useState({});
  const [leagues, setLeagues] = useState({}); // for pre, sid-country-league
  const [btag, setBtag] = useState(""); // btag

  const toggleCasinoGames = (lid) => {
    setCasinoGames((prev) => {
      if (prev[lid]) {
        const { [lid]: removedKey, ...rest } = prev;

        return rest;
      } else {
        return { ...prev, [lid]: 1 };
      }
    });
  };
  const toggleLastPlayed = (lid) => {
    setLastPlayed((prev) => {
      if (prev[lid]) {
        const { [lid]: removedKey, ...rest } = prev;

        return rest;
      } else {
        return { ...prev, [lid]: 1 };
      }
    });
  };
  const toggleLeague = (lid) => {
    setLeagues((prev) => {
      if (prev[lid]) {
        const { [lid]: removedKey, ...rest } = prev;

        return rest;
      } else {
        return { ...prev, [lid]: 1 };
      }
    });
  };

  const toggleMarketType = (mt) => {
    setMarketTypes((prev) => {
      if (prev[mt]) {
        const { [mt]: removedKey, ...rest } = prev;

        return rest;
      } else {
        return { ...prev, [mt]: 1 };
      }
    });
  };
  const toggleEvents = (mt) => {
    setEvents((prev) => {
      if (prev[mt]) {
        const { [mt]: removedKey, ...rest } = prev;

        return rest;
      } else {
        return { ...prev, [mt]: 1 };
      }
    });
  };
  useEffect(() => {
    const savedData = localStorage.getItem("casinoGames");
    if (savedData) {
      setCasinoGames(JSON.parse(savedData));
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("casinoGames", JSON.stringify(casinoGames));
  }, [casinoGames]);
  useEffect(() => {
    const savedData = localStorage.getItem("events");
    if (savedData) {
      setEvents(JSON.parse(savedData));
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("events", JSON.stringify(events));
  }, [events]);

  useEffect(() => {
    const savedData = localStorage.getItem("leagues");
    if (savedData) {
      setLeagues(JSON.parse(savedData));
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("leagues", JSON.stringify(leagues));
  }, [leagues]);
  useEffect(() => {
    const savedData = localStorage.getItem("marketTypes");
    if (savedData) {
      setMarketTypes(JSON.parse(savedData));
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("marketTypes", JSON.stringify(marketTypes));
  }, [marketTypes]);

  useEffect(() => {
    const savedData = localStorage.getItem("btag");
    if (savedData) {
      setBtag(JSON.parse(savedData));
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("btag", JSON.stringify(btag));
  }, [btag]);
  return (
    <FavoritesContext.Provider
      value={{
        casinoGames,
        toggleCasinoGames,
        lastPlayed,
        toggleLastPlayed,
        leagues,
        toggleLeague,
        marketTypes,
        toggleMarketType,
        events,
        toggleEvents,
        btag,
        setBtag,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
}

export function useFavorites() {
  return useContext(FavoritesContext);
}
