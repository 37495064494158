import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";

import App from "./App";
import "./assets/css/app.css";

import { VisibilityProvider } from "./context/VisibilityContext";
import { DeviceSizeProvider } from "./context/DeviceSizeContext";
import { ConfigProvider } from "./context/ConfigContext";
import { PrefencesProvider } from "./context/PrefencesContext";
import { FavoritesProvider } from "./context/FavoritesContext";
import { TimezoneProvider } from "./context/TimezoneContext";
import { LocationsProvider } from "./context/LocationsContext";
import { SocketProvider } from "./context/SocketContext";

axios.defaults.withCredentials = true;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <DeviceSizeProvider>
    <VisibilityProvider>
      <ConfigProvider>
        <PrefencesProvider>
          <FavoritesProvider>
            <TimezoneProvider>
              <SocketProvider>
                <BrowserRouter>
                  <LocationsProvider>
                    <App />
                  </LocationsProvider>
                </BrowserRouter>
              </SocketProvider>
            </TimezoneProvider>
          </FavoritesProvider>
        </PrefencesProvider>
      </ConfigProvider>
    </VisibilityProvider>
  </DeviceSizeProvider>
);
