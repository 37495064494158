import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Requests from "../../helpers/Requests";
import { Link, useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "./Slider.css";
import {
  Pagination,
  Navigation,
  Scrollbar,
  Autoplay,
  A11y,
} from "swiper/modules";

function Slider({ platform, location }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    callSliders();
    // eslint-disable-next-line
  }, [platform, location]);
  const callSliders = () => {
    const fetchData = async () => {
      const data = await Requests.postData("/general/getSliders", {
        platform: platform,
        location: location,
      });
      return data;
    };

    fetchData()
      .then((res) => {
        if (res.data.type === "success") {
          setData(res.data.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        Requests.diagnostics(err, navigate);
        setLoading(false);
      });
  };
  return (
    <>
      {loading | (data.length === 0) ? (
        <div className={(loading ? "animate-pulse " : "") + "w-full relative"}>
          <div className="flex items-center justify-center w-full bg-layout-bg rounded-lg aspect-[403/222] lg:aspect-[883/170]">
            <svg
              className="w-10 h-10"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 18"
            >
              <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
            </svg>
          </div>
        </div>
      ) : (
        <div className="aspect-[403/222] lg:aspect-[883/170] w-full rounded-lg relative">
          <Swiper
            slidesPerView={"auto"}
            navigation={true}
            modules={[Pagination, Navigation, Scrollbar, Autoplay, A11y]}
            scrollbar={{ draggable: true }}
            className="mySwiper"
            autoplay={{ delay: 3000, disableOnInteraction: false }}
          >
            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <Link
                  to={item.link && item.link.length > 0 ? item.link : undefined}
                >
                  <LazyLoadImage
                    src={
                      process.env.REACT_APP_CDN_ENDPOINT +
                      "/upload/img/" +
                      item.image
                    }
                    alt={item.title}
                    className="w-full rounded-lg"
                  />
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  );
}

export default Slider;
