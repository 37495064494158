import React, { useEffect, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import { useNavigate } from "react-router-dom";
import {
  ArrowRightCircleIcon,
  AtSymbolIcon,
} from "@heroicons/react/24/outline";
import LoadingSpinner from "../components/Common/Loading/LoadingSpinner";
import Alert from "../components/Common/Alert";
import CountryDropdown from "../components/Common/FormElements/CountryDropdown";
import Requests from "../helpers/Requests";
import GenderRadio from "../components/Common/FormElements/GenderRadio";

function Profile() {
  const ConfigProvider = useConfig();
  const navigate = useNavigate();
  useEffect(() => {
    if (ConfigProvider.config.user === null) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const [formData, setFormData] = useState({
    gender: "M",
    name: "",
    sname: "",
    dateOfBirth: "",
    country: "",
    postalCode: "",
    city: "",
    address: "",
    email: "",
    phone: "",
    nationality: "",
    countryOfBirth: "",
    identity: "",
    allowIdentityUpdate: false,
    allowEmailUpdate: false,
    allowNameUpdate: false,
    allowSnameUpdate: false,
  });
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      if (name === "phone") {
        const regex = /^[+\d- ]*$/;
        if (regex.test(value) && value.length <= 15) {
          setFormData({ ...formData, [name]: value.split(" ").join("") });
        }
      } else {
        setFormData({ ...formData, [name]: value });
      }
    }
  };
  const [messages, setMessages] = useState([]);
  const updateForm = (e) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      setMessages([]);
      let requiredFieldErros = [];
      if (formData.gender.length === 0) {
        requiredFieldErros.push(ConfigProvider.getTranslation("Gender"));
      }
      if (formData.nationality.length === 0) {
        requiredFieldErros.push(ConfigProvider.getTranslation("Nationality"));
      }
      if (formData.countryOfBirth.length === 0) {
        requiredFieldErros.push(
          ConfigProvider.getTranslation("CountryOfBirth")
        );
      }
      if (formData.country.length === 0) {
        requiredFieldErros.push(ConfigProvider.getTranslation("Country"));
      }

      if (requiredFieldErros.length > 0) {
        setMessages([
          {
            type: "error",
            text:
              "[" +
              requiredFieldErros.join(",") +
              "] " +
              ConfigProvider.getTranslation("Forms.EmptyValueError"),
          },
        ]);
        window.scrollTo(0, 0);
        setLoading(false);
      } else {
        Requests.postData("/user/updatePersonalInfo", formData)
          .then((response) => {
            let res = response.data;
            if (res.type !== "success") {
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
              window.scrollTo(0, 0);
            } else {
              setMessages([
                {
                  type: "success",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.SuccessMessage"),
                },
              ]);
              window.scrollTo(0, 0);
            }
            getData();
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };
  const getData = () => {
    Requests.postData("/user/getPersonalInfo", {})
      .then((response) => {
        let res = response.data;
        if (res.type !== "success") {
          window.location.href = "/";
        } else {
          let newData = res.data;
          if (newData.dateOfBirth === null) {
            newData.dateOfBirth = "";
          }
          setFormData(newData);
          setFirstLoading(false);
        }
      })
      .catch(() => {
        window.location.href = "/";
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return firstLoading ? (
    <>
      <div className="flex justify-center py-3 md:py-10 px-3">
        <div className="flex flex-col w-full lg:w-2/3 max-w-screen-md">
          <div className="border-b border-color-2 pb-4">
            <div className="w-full">
              <div
                className={
                  (firstLoading ? "animate-pulse " : "") +
                  "w-full bg-layout-bg rounded py-3"
                }
              >
                &nbsp;
              </div>
            </div>
          </div>

          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, key) => (
            <div key={key} className="w-full py-3">
              <div
                className={
                  (firstLoading ? "animate-pulse " : "") +
                  "w-full bg-layout-bg rounded mb-1"
                }
              >
                &nbsp;
              </div>
              <div
                className={
                  (firstLoading ? "animate-pulse " : "") +
                  "w-full bg-layout-bg rounded py-2"
                }
              >
                &nbsp;
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  ) : (
    <div className="flex justify-center py-3 md:py-10 px-3">
      <div className="flex flex-col w-full lg:w-2/3 max-w-screen-md">
        <div className="border-b border-color-2 pb-4">
          <div className="text-center text-xl sm:text-2xl text-color-1 ">
            {ConfigProvider.getTranslation("PersonalInformation")}
          </div>
        </div>
        <div className="mt-3">
          <form className="flex-none w-full" onSubmit={updateForm}>
            <div className="mt-2 mb-4">
              <Alert messages={messages} setMessages={setMessages} />
            </div>
            <div className="w-full p-4">
              <div className="flex flex-col mb-6">
                <label
                  htmlFor="gender"
                  className="mb-1 text-xs sm:text-sm tracking-wid"
                >
                  {ConfigProvider.getTranslation("Gender")}:
                </label>
                <div className="relative">
                  <GenderRadio
                    onUpdate={(value) => {
                      setFormData({ ...formData, gender: value });
                    }}
                    val={formData.gender}
                  />
                </div>
              </div>
              <div className="flex flex-col mb-6">
                <label
                  htmlFor="name"
                  className="mb-1 text-xs sm:text-sm tracking-wid"
                >
                  {ConfigProvider.getTranslation("FirstName")}:
                </label>
                <div className="relative">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                    placeholder={ConfigProvider.getTranslation("FirstName")}
                    value={formData.name}
                    onChange={handleChange}
                    required
                    maxLength={50}
                    readOnly={!formData.allowNameUpdate}
                    disabled={!formData.allowNameUpdate}
                  />
                </div>
              </div>
              <div className="flex flex-col mb-6">
                <label
                  htmlFor="sname"
                  className="mb-1 text-xs sm:text-sm tracking-wid"
                >
                  {ConfigProvider.getTranslation("Surname")}:
                </label>
                <div className="relative">
                  <input
                    id="sname"
                    name="sname"
                    type="text"
                    className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                    placeholder={ConfigProvider.getTranslation("Surname")}
                    value={formData.sname}
                    onChange={handleChange}
                    required
                    maxLength={50}
                    readOnly={!formData.allowSnameUpdate}
                    disabled={!formData.allowSnameUpdate}
                  />
                </div>
              </div>
              <div className="flex flex-col mb-6">
                <label
                  htmlFor="dateOfBirth"
                  className="mb-1 text-xs sm:text-sm tracking-wid"
                >
                  {ConfigProvider.getTranslation("DateOfBirth")}:
                </label>
                <div className="relative">
                  <input
                    id="dateOfBirth"
                    name="dateOfBirth"
                    type="date"
                    className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                    placeholder={ConfigProvider.getTranslation("DateOfBirth")}
                    value={formData.dateOfBirth}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col mb-6">
                <div className="relative">
                  <CountryDropdown
                    name={ConfigProvider.getTranslation("Country")}
                    onUpdate={(value) => {
                      setFormData({ ...formData, country: value });
                    }}
                    val={formData.country}
                  />
                </div>
              </div>
              <div className="flex flex-col mb-6">
                <label
                  htmlFor="postalCode"
                  className="mb-1 text-xs sm:text-sm tracking-wid"
                >
                  {ConfigProvider.getTranslation("PostalCode")}:
                </label>
                <div className="relative">
                  <input
                    id="postalCode"
                    name="postalCode"
                    type="text"
                    className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                    placeholder={ConfigProvider.getTranslation("PostalCode")}
                    value={formData.postalCode}
                    onChange={handleChange}
                    required
                    maxLength={10}
                  />
                </div>
              </div>
              <div className="flex flex-col mb-6">
                <label
                  htmlFor="city"
                  className="mb-1 text-xs sm:text-sm tracking-wid"
                >
                  {ConfigProvider.getTranslation("City")}:
                </label>
                <div className="relative">
                  <input
                    id="city"
                    name="city"
                    type="text"
                    className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                    placeholder={ConfigProvider.getTranslation("City")}
                    value={formData.city}
                    onChange={handleChange}
                    required
                    maxLength={20}
                  />
                </div>
              </div>
              <div className="flex flex-col mb-6">
                <label
                  htmlFor="address"
                  className="mb-1 text-xs sm:text-sm tracking-wid"
                >
                  {ConfigProvider.getTranslation("Address")}:
                </label>
                <div className="relative">
                  <input
                    id="UnderReview"
                    name="address"
                    type="text"
                    className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                    placeholder={ConfigProvider.getTranslation("Address")}
                    value={formData.address}
                    onChange={handleChange}
                    required
                    maxLength={50}
                  />
                </div>
              </div>
              <div className="flex flex-col mb-6">
                <label
                  htmlFor="email"
                  className="mb-1 text-xs sm:text-sm tracking-wide"
                >
                  {ConfigProvider.getTranslation("E-mail")}:
                </label>
                <div className="relative">
                  <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10">
                    <AtSymbolIcon className="w-6 h-6 text-page-text" />
                  </div>

                  <input
                    id="email"
                    name="email"
                    type="email"
                    className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md pl-10 pr-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                    placeholder={ConfigProvider.getTranslation("E-mail")}
                    value={formData.email}
                    onChange={handleChange}
                    required
                    maxLength={255}
                    readOnly={!formData.allowEmailUpdate}
                    disabled={!formData.allowEmailUpdate}
                  />
                </div>
              </div>
              <div className="flex flex-col mb-6">
                <label
                  htmlFor="phone"
                  className="mb-1 text-xs sm:text-sm tracking-wid"
                >
                  {ConfigProvider.getTranslation("Phone")}:
                </label>
                <div className="relative">
                  <input
                    id="phone"
                    name="phone"
                    type="text"
                    className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                    placeholder={ConfigProvider.getTranslation("Phone")}
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    maxLength={20}
                  />
                </div>
              </div>
              <div className="flex flex-col mb-6">
                <label
                  htmlFor="identity"
                  className="mb-1 text-xs sm:text-sm tracking-wid"
                >
                  {ConfigProvider.getTranslation("Identity")}:
                </label>
                <div className="relative">
                  <input
                    id="identity"
                    name="identity"
                    type="text"
                    className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                    placeholder={ConfigProvider.getTranslation("Identity")}
                    value={formData.identity}
                    onChange={handleChange}
                    maxLength={20}
                    readOnly={!formData.allowIdentityUpdate}
                    disabled={!formData.allowIdentityUpdate}
                  />
                </div>
              </div>
              <div className="flex flex-col mb-6">
                <div className="relative">
                  <CountryDropdown
                    name={ConfigProvider.getTranslation("Nationality")}
                    onUpdate={(value) => {
                      setFormData({ ...formData, nationality: value });
                    }}
                    val={formData.nationality}
                  />
                </div>
              </div>
              <div className="flex flex-col mb-6">
                <div className="relative">
                  <CountryDropdown
                    name={ConfigProvider.getTranslation("CountryOfBirth")}
                    onUpdate={(value) => {
                      setFormData({ ...formData, countryOfBirth: value });
                    }}
                    val={formData.countryOfBirth}
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <button
                  type="submit"
                  className="flex items-center justify-center focus:outline-none text-sm sm:text-base rounded py-2 w-full transition duration-150 ease-in bg-home-from-8 text-page-bg font-bold"
                  disabled={loading}
                >
                  <span className="mr-2 uppercase">
                    {ConfigProvider.getTranslation("ApplyingChanges")}
                  </span>
                  {loading ? (
                    <span>
                      <LoadingSpinner className="w-6 h-6" />
                    </span>
                  ) : (
                    <span>
                      <ArrowRightCircleIcon className="w-6 h-6" />
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Profile;
