import { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import Requests from "../../helpers/Requests";
import JackpotAnimation from "./JackpotAnimation";

function JackpotContainer() {
  const ConfigProvider = useConfig();
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const data = await Requests.postData("/user/getJackpotPrize", {});
      return data;
    };
    fetchData()
      .then((response) => {
        let res = response.data;
        if (res.type === "success") {
          if (res.data === null) ConfigProvider.updateJackpotPrize(false);
          else {
            setData(res.data);
          }
        }
      })
      .catch((err) => {});
    // eslint-disable-next-line
  }, []);
  return data ? (
    <JackpotAnimation
      item={data}
      setShowJackpot={() => {
        ConfigProvider.updateJackpotPrize(false);
      }}
    />
  ) : null;
}
export default JackpotContainer;
