import { renderToStaticMarkup } from "react-dom/server";
import {
  ArrowUturnDownIcon,
  KeyIcon,
  UserCircleIcon,
  BanknotesIcon,
  BuildingLibraryIcon,
  PaperAirplaneIcon,
  AdjustmentsVerticalIcon,
  DocumentChartBarIcon,
  GiftIcon,
  QueueListIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/solid";

const mobileStaticMenuArray = (ConfigProvider, exclude = []) => {
  let send = [];
  if (ConfigProvider.config.user !== null) {
    send = [
      {
        target: "_self",
        link: "/transactionHistory",
        icon: renderToStaticMarkup(<DocumentChartBarIcon />),
        name: ConfigProvider.getTranslation("TransactionHistory"),
        translation: {
          en: ConfigProvider.getTranslation("TransactionHistory"),
        },
        current: false,
      },
      {
        target: "_self",
        link: "/profile",
        icon: renderToStaticMarkup(<UserCircleIcon />),
        name: ConfigProvider.getTranslation("PersonalInformation"),
        translation: {
          en: ConfigProvider.getTranslation("PersonalInformation"),
        },
        current: false,
      },
      {
        target: "_self",
        link: "/sendMoney",
        icon: renderToStaticMarkup(<PaperAirplaneIcon />),
        name: ConfigProvider.getTranslation("SendMoney"),
        translation: {
          en: ConfigProvider.getTranslation("SendMoney"),
        },
        current: false,
      },
      {
        target: "_self",
        link: "/deposit",
        icon: renderToStaticMarkup(<BanknotesIcon />),
        name: ConfigProvider.getTranslation("Deposit"),
        translation: {
          en: ConfigProvider.getTranslation("Deposit"),
        },
        current: false,
      },
      {
        target: "_self",
        link: "/withdrawal",
        icon: renderToStaticMarkup(<BuildingLibraryIcon />),
        name: ConfigProvider.getTranslation("Withdrawal"),
        translation: {
          en: ConfigProvider.getTranslation("Withdrawal"),
        },
        current: false,
      },
      {
        target: "_self",
        link: "/paymentTransactionHistory",
        icon: renderToStaticMarkup(<ArrowUturnDownIcon />),
        name: ConfigProvider.getTranslation("PaymentTransactionHistory"),
        translation: {
          en: ConfigProvider.getTranslation("PaymentTransactionHistory"),
        },
        current: false,
      },
      {
        target: "_self",
        link: "/bonuses",
        icon: renderToStaticMarkup(<GiftIcon />),
        name: ConfigProvider.getTranslation("Bonuses"),
        translation: {
          en: ConfigProvider.getTranslation("Bonuses"),
        },
        current: false,
      },
      {
        target: "_self",
        link: "/bonusHistory",
        icon: renderToStaticMarkup(<QueueListIcon />),
        name: ConfigProvider.getTranslation("BonusHistory"),
        translation: {
          en: ConfigProvider.getTranslation("BonusHistory"),
        },
        current: false,
      },
      {
        target: "_self",
        link: "/changePassword",
        icon: renderToStaticMarkup(<KeyIcon />),
        name: ConfigProvider.getTranslation("Password&Security"),
        translation: {
          en: ConfigProvider.getTranslation("Password&Security"),
        },
        current: false,
      },
      {
        target: "_self",
        link: "/limits",
        icon: renderToStaticMarkup(<AdjustmentsVerticalIcon />),
        name: ConfigProvider.getTranslation("Limits"),
        translation: {
          en: ConfigProvider.getTranslation("Limits"),
        },
        current: false,
      },
      {
        target: "_self",
        link: "/messages",
        icon: renderToStaticMarkup(<EnvelopeIcon />),
        name: ConfigProvider.getTranslation("Messages"),
        translation: {
          en: ConfigProvider.getTranslation("Messages"),
        },
        current: false,
      },
    ];
  }
  if (exclude) {
    send = send.filter((x) => exclude.indexOf(x.link) === -1);
  }
  if (!ConfigProvider.config.adminsetting.allowPlayersMoneyTransfer) {
    send = send.filter((x) => x.link !== "/sendMoney");
  }
  return send;
};

export { mobileStaticMenuArray };
