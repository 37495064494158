import React, { useEffect, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import { useNavigate } from "react-router-dom";
import {
  ArrowRightCircleIcon,
  KeyIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import LoadingSpinner from "../components/Common/Loading/LoadingSpinner";
import Alert from "../components/Common/Alert";
import Requests from "../helpers/Requests";

function PasswordRecovery() {
  const ConfigProvider = useConfig();
  const navigate = useNavigate();
  useEffect(() => {
    if (ConfigProvider.config.user) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div className="flex justify-center py-3 md:py-10 px-3">
      <div className="flex flex-col w-full lg:w-2/3 max-w-screen-md">
        <div className="self-center text-xl sm:text-2xl font-bold">
          {ConfigProvider.getTranslation("Password&Security")}
        </div>
        <div className="mt-3 md:mt-10">
          <PasswordRecoveryForm />
        </div>
      </div>
    </div>
  );
}
function PasswordRecoveryForm() {
  const ConfigProvider = useConfig();

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    password: "",
    password2: "",
  });
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  const [messages, setMessages] = useState([]);
  const updateForm = (e) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      setMessages([]);
      let requiredFieldErros = [];

      if (requiredFieldErros.length > 0) {
        setMessages([
          {
            type: "error",
            text:
              "[" +
              requiredFieldErros.join(",") +
              "] " +
              ConfigProvider.getTranslation("Forms.EmptyValueError"),
          },
        ]);
        window.scrollTo(0, 0);
        setLoading(false);
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const user = urlParams.get("user");
        const code = urlParams.get("code");

        let postData = {
          user: user,
          code: code,
          password: formData.password,
          password2: formData.password2,
        };
        Requests.postData("/user/recoveryPassword", postData)
          .then((response) => {
            let res = response.data;
            if (res.type !== "success") {
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
              window.scrollTo(0, 0);
            } else {
              setMessages([
                {
                  type: "success",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.SuccessMessage"),
                },
              ]);
              setFormData({ ...formData, password: "", password2: "" });
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  return (
    <form className="flex-none w-full" onSubmit={updateForm}>
      <div className="mt-2 mb-4">
        <Alert messages={messages} setMessages={setMessages} />
      </div>
      <div className="w-full p-4 text-page-text">
        <div className="w-full text-center text-lg py-2 border-b border-color-2 mb-5 font-bold text-page-text">
          {ConfigProvider.getTranslation("EnterANewPassword")}
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="password"
            className="mb-1 text-xs sm:text-sm tracking-wide"
          >
            {ConfigProvider.getTranslation("Password")}:
          </label>
          <div className="relative">
            <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10">
              <KeyIcon className="w-6 h-6 text-color-3" />
            </div>

            <input
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md pl-10 pr-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
              placeholder="*******"
              value={formData.password}
              onChange={handleChange}
              required
              maxLength={15}
            />
            <div
              className="inline-flex items-center justify-center absolute right-0 top-0 h-full w-10 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {!showPassword ? (
                <EyeIcon className="w-4 h-4 text-color-3" />
              ) : (
                <EyeSlashIcon className="w-4 h-4 text-color-3" />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col mb-6">
          <label
            htmlFor="password2"
            className="mb-1 text-xs sm:text-sm tracking-wide"
          >
            {ConfigProvider.getTranslation("Password.Again")}:
          </label>
          <div className="relative">
            <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10">
              <KeyIcon className="w-6 h-6 text-color-3" />
            </div>

            <input
              id="password2"
              name="password2"
              type={showPassword ? "text" : "password"}
              className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md pl-10 pr-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
              placeholder="*******"
              value={formData.password2}
              onChange={handleChange}
              required
              maxLength={15}
            />
            <div
              className="inline-flex items-center justify-center absolute right-0 top-0 h-full w-10 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {!showPassword ? (
                <EyeIcon className="w-4 h-4 text-color-3" />
              ) : (
                <EyeSlashIcon className="w-4 h-4 text-color-3" />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <button
            type="submit"
            className="flex items-center justify-center focus:outline-none text-sm sm:text-base rounded py-2 w-full transition duration-150 ease-in bg-home-from-8 text-page-bg font-bold"
            disabled={loading}
          >
            <span className="mr-2 uppercase">
              {ConfigProvider.getTranslation("ChangePassword")}
            </span>
            {loading ? (
              <span>
                <LoadingSpinner className="w-6 h-6" />
              </span>
            ) : (
              <span>
                <ArrowRightCircleIcon className="w-6 h-6" />
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
}

export default PasswordRecovery;
