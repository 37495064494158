import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
const LocationsContext = createContext();

export function LocationsProvider({ children }) {
  const location = useLocation();
  const [from, setFrom] = useState(0);

  useEffect(() => {
    setFrom((prev) => (prev >= 0 ? prev + 1 : 0));
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const goBack = () => {
    window.history.back();
  };
  return (
    <LocationsContext.Provider
      value={{
        from,
        goBack,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
}

export function useLocations() {
  return useContext(LocationsContext);
}
