import React, { useState } from "react";
import { useConfig } from "../../../context/ConfigContext";

function SelfExclusionRadio({ onUpdate, val }) {
  let ConfigProvider = useConfig();
  const elements = [
    {
      id: "3m",
      name: "3 " + ConfigProvider.getTranslation("Month"),
    },
    { id: "6m", name: "6 " + ConfigProvider.getTranslation("Month") },
    { id: "9m", name: "9 " + ConfigProvider.getTranslation("Month") },
    { id: "1y", name: "1 " + ConfigProvider.getTranslation("Year") },
    { id: "3y", name: "3 " + ConfigProvider.getTranslation("Year") },
    { id: "5y", name: "5 " + ConfigProvider.getTranslation("Year") },
  ];

  const [itemValue, setItemValue] = useState(val && val !== null ? val : "");
  const handleChange = (e) => {
    const { value } = e.target;
    setItemValue(value);
    onUpdate(value);
  };
  return (
    <fieldset className="mt-4">
      <legend className="sr-only">
        {ConfigProvider.getTranslation("Period")}
      </legend>
      <div className="grid grid-cols-2 gap-4">
        {elements.map((item) => (
          <div key={item.id} className="flex items-center">
            <input
              id={item.id}
              name="period"
              type="radio"
              className="h-4 w-4 ring-0"
              required
              onChange={handleChange}
              value={item.id}
              checked={itemValue === item.id}
            />
            <label htmlFor={item.id} className="ml-3 block text-sm">
              {item.name}
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  );
}

export default SelfExclusionRadio;
