import React, { useState, Fragment } from "react";
import { useConfig } from "../../../context/ConfigContext";
import { Link, NavLink } from "react-router-dom";
import {
  ArrowPathIcon,
  ArrowRightOnRectangleIcon,
  BanknotesIcon,
  Bars3Icon,
  BuildingLibraryIcon,
  ChevronDownIcon,
  EnvelopeIcon,
  GiftIcon,
  StarIcon,
  UserCircleIcon,
  UserIcon,
  UserPlusIcon,
} from "@heroicons/react/24/solid";
import { ReactComponent as LuckysevenSVG } from "../../../assets/svg/seven.svg";
import { ReactComponent as RouletteSVG } from "../../../assets/img/roulette.svg";
import { Dialog, Transition } from "@headlessui/react";
import { classNames } from "../../../helpers/Helpers";
import Confirm from "../../../components/Common/Confirm";
import { SvgLoader } from "react-svgmt";
import { ReactComponent as Headphones } from "../../../assets/svg/headphones.svg";
function FooterNav({
  mobileMenuOpen,
  setMobileMenuOpen,
  setFavoritesSidebarOpen,
}) {
  const ConfigProvider = useConfig();

  return (
    <div className="md:hidden">
      <div className="w-full">
        <div className="h-[52px] w-full">&nbsp;</div>
      </div>
      <div className="w-full shadow bottom-0 z-40 fixed grid grid-cols-5 h-[52px] bg-layout-bg border-t border-layout-border text-footer-nav-text font-bold">
        <div className="border-r border-color-2">
          <NavLink
            to="/casino/slot"
            className={({ isActive, isPending }) =>
              (isPending || isActive ? "text-gradient-gold-a" : "") +
              " text-[10px] flex flex-col space-y-0.5 w-full h-full justify-center items-center"
            }
          >
            <LuckysevenSVG className="w-5 h-5 fill-current p-0.5" />
            <span>{ConfigProvider.getTranslation("Slot")}</span>
          </NavLink>
        </div>
        <div className="border-r border-color-2">
          <NavLink
            to="/casino/liveCasino"
            className={({ isActive, isPending }) =>
              (isPending || isActive ? "text-gradient-gold-a" : "") +
              " text-[10px] flex flex-col space-y-0.5 w-full h-full justify-center items-center"
            }
          >
            <RouletteSVG className="w-5 h-5 fill-current" />
            <span>{ConfigProvider.getTranslation("LiveCasino")}</span>
          </NavLink>
        </div>
        {ConfigProvider.config.user === null ? (
          <>
            <div className="border-r border-color-2">
              <NavLink
                to="/login"
                className={({ isActive, isPending }) =>
                  (isPending || isActive ? "text-gradient-gold-a" : "") +
                  " text-[10px] flex flex-col space-y-0.5 w-full h-full justify-center items-center"
                }
              >
                <ArrowRightOnRectangleIcon className="w-5 h-5 fill-current" />
                <span>{ConfigProvider.getTranslation("Login")}</span>
              </NavLink>
            </div>
            <div className="border-r border-color-2">
              <NavLink
                to="/registerFull"
                className={({ isActive, isPending }) =>
                  (isPending || isActive ? "text-gradient-gold-a" : "") +
                  " text-[10px] flex flex-col space-y-0.5 w-full h-full justify-center items-center"
                }
              >
                <UserPlusIcon className="w-5 h-5 fill-current" />
                <span>{ConfigProvider.getTranslation("Register")}</span>
              </NavLink>
            </div>
          </>
        ) : (
          <>
            <div className="border-r border-color-2">
              <NavLink
                to="/transactionHistory"
                className={({ isActive, isPending }) =>
                  (isPending || isActive ? "text-gradient-gold-a" : "") +
                  " text-[10px] flex flex-col space-y-0.5 w-full h-full justify-center items-center"
                }
              >
                <UserIcon className="w-5 h-5 fill-current" />
                <span>
                  {ConfigProvider.config.user.balance.toFixed(2) +
                    " " +
                    ConfigProvider.config.user.currency}
                </span>
              </NavLink>
            </div>
            <div className="border-r border-color-2">
              <Link
                onClick={() => {
                  setMobileMenuOpen(false);
                  setTimeout(() => {
                    setFavoritesSidebarOpen(true);
                  }, 150);
                }}
                className="text-[10px] flex flex-col space-y-0.5 w-full h-full justify-center items-center"
              >
                <StarIcon className="w-5 h-5 fill-current" />
                <span>{ConfigProvider.getTranslation("Favorites")}</span>
              </Link>
            </div>
          </>
        )}
        <div className="border-r border-color-2">
          <Link
            onClick={() => {
              setMobileMenuOpen(!mobileMenuOpen);
            }}
            className="text-[10px] flex flex-col space-y-0.5 w-full h-full justify-center items-center"
          >
            <Bars3Icon className="w-5 h-5 fill-current" />
            <span>{ConfigProvider.getTranslation("Menu")}</span>
          </Link>
        </div>
      </div>
      <FooterMenu
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />
    </div>
  );
}
function FooterMenu({ mobileMenuOpen, setMobileMenuOpen }) {
  const ConfigProvider = useConfig();
  const conf = ConfigProvider.config;
  const [signout, setSignOut] = useState(false);

  const [isClickedRefresh, setIsClickedRefresh] = useState(false);

  const checkUser = () => {
    ConfigProvider.checkUser();
    setIsClickedRefresh(true);
    var timeout = setTimeout(() => {
      setIsClickedRefresh(false);
    }, 1000);
    return () => {
      window.clearTimeout(timeout);
    };
  };

  return (
    <>
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => {
            setMobileMenuOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 top-16" />
          </Transition.Child>

          <div className="fixed inset-y-0 z-50 flex right-0 max-w-md w-screen top-16 bottom-[52px] bg-gray-500/50">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full flex-1 flex-col text-page-text text-sm bg-page-bg overflow-y-auto ">
                {ConfigProvider.config.user !== null ? (
                  <nav className="flex w-full items-center flex-col py-2 border-b">
                    <UserCircleIcon className="w-16" />
                    <div className="text-base">
                      {ConfigProvider.config.user.uname}
                    </div>
                    <div className="text-white text-lg flex items-center space-x-1">
                      <Link
                        onClick={checkUser}
                        className="text-gradient-gold-b"
                      >
                        <ArrowPathIcon
                          className={`w-5 fill-current ${
                            isClickedRefresh ? "animate-spin" : ""
                          }`}
                        />
                      </Link>
                      <div className="grow">
                        {parseFloat(ConfigProvider.config.user.balance).toFixed(
                          2
                        ) +
                          " " +
                          ConfigProvider.config.user.currency}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-2 py-2 w-full font-bold">
                      <Link
                        to="/deposit"
                        className="flex rounded-md p-2 mx-3 my-2 bg-home-from-3 text-xs items-center text-white"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <BanknotesIcon className="w-5 h-5 mr-2" />
                        {ConfigProvider.getTranslation("Deposit")}
                      </Link>
                      <Link
                        to="/withdrawal"
                        className="flex rounded-md p-2 mx-3 my-2 bg-home-from-4 text-xs items-center text-white"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <BuildingLibraryIcon className="w-5 h-5 mr-2" />
                        {ConfigProvider.getTranslation("Withdrawal")}
                      </Link>
                      <Link
                        to="/messages"
                        onClick={() => {
                          setMobileMenuOpen(false);
                        }}
                        className="flex rounded-md p-2 mx-3 my-2 bg-home-from-1 text-xs items-center text-white"
                      >
                        <EnvelopeIcon className="w-5 h-5 mr-2" />
                        {ConfigProvider.getTranslation("Messages")}
                        {ConfigProvider.config.user.unreadMessages > 0 && (
                          <span className="ml-2 animate-bounce text-amber-700">
                            ({ConfigProvider.config.user.unreadMessages})
                          </span>
                        )}
                      </Link>
                      <Link
                        onClick={() => {
                          setMobileMenuOpen(false);
                          setTimeout(() => {
                            setSignOut(true);
                          }, 500);
                        }}
                        className="flex rounded-md p-2 mx-3 my-2 bg-home-from-5 text-xs items-center text-white"
                      >
                        <ArrowRightOnRectangleIcon className="w-5 h-5 mr-2" />
                        {ConfigProvider.getTranslation("SignOut")}
                      </Link>
                    </div>
                  </nav>
                ) : null}
                <nav className="flex flex-col">
                  <div className="text-footer-nav-text">
                    <Menus conf={conf} setMobileMenuOpen={setMobileMenuOpen} />
                    <Link
                      to="/promotions"
                      onClick={() => {
                        setMobileMenuOpen(false);
                      }}
                      className="group w-full"
                    >
                      <div className="w-full p-2">
                        <div className="bg-left-title-bg flex h-12 w-full items-center justify-between rounded-md p-1">
                          <div className="flex">
                            <GiftIcon className="mx-4 w-5 h-5 text-left-title-text" />
                            <div className="text-sm font-bold text-left-title-text">
                              {ConfigProvider.getTranslation("Promotions")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link
                      onClick={() => {
                        try {
                          // eslint-disable-next-line
                          //eval('$crisp.push(["do", "chat:open"])');
                          //eval("Comm100API.open_chat_window()");
                          // eslint-disable-next-line
                          eval(ConfigProvider.config.cms.liveSupportOpenWindow);
                        } catch (e) {}
                        setMobileMenuOpen(false);
                      }}
                      className="group w-full"
                    >
                      <div className="w-full p-2">
                        <div className="bg-left-title-bg flex h-12 w-full items-center justify-between rounded-md p-1">
                          <div className="flex">
                            <Headphones className="mx-4 w-5 h-5 fill-left-title-text" />
                            <div className="text-sm font-bold text-left-title-text">
                              {ConfigProvider.getTranslation("LiveSupport")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="p-5">
                      <SocialMedias />
                    </div>
                  </div>
                </nav>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {signout ? (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={ConfigProvider.getTranslation("Logs.Logout")}
          confirm={ConfigProvider.signout}
          callback={() => {
            setSignOut(false);
          }}
        />
      ) : null}
    </>
  );
}

function SocialMedias() {
  const ConfigProvider = useConfig();

  return ConfigProvider.config &&
    ConfigProvider.config.socialMedia &&
    ConfigProvider.config.socialMedia.length > 0 ? (
    <div className="flex items-center justify-center space-x-2">
      {ConfigProvider.config.socialMedia.map((item, index) => (
        <Link
          target="_blank"
          to={item.link}
          key={index}
          className="border rounded-full py-1.5 border-left-title-bg text-left-title-bg hover:bg-left-title-bg hover:text-left-title-text"
          title={item.name}
        >
          <SvgLoader
            svgXML={item.icon}
            className="mx-3 w-5 h-5 fill-current"
          ></SvgLoader>
        </Link>
      ))}
    </div>
  ) : null;
}
function MobileMenuSubItems({
  item,
  ConfigProvider,
  setMobileMenuOpen,
  menus,
  child = false,
}) {
  return item.target === "_self" ? (
    <>
      <Link
        to={item.link.length > 0 ? item.link : undefined}
        className={classNames(
          item.current ? "" : "",
          "group py-3 flex items-center text-sm font-bold border-b",
          child ? "pl-6 pr-4" : "px-4"
        )}
        onClick={() => setMobileMenuOpen(false)}
      >
        {item.icon && (
          <SvgLoader
            svgXML={item.icon}
            className="w-4 h-4 mr-3 fill-current"
          ></SvgLoader>
        )}
        <span className="grow">{ConfigProvider.translateItem(item)}</span>
        <span>
          {menus && menus.length > 0 && <ChevronDownIcon className="w-5 h-5" />}
        </span>
      </Link>
      {menus && menus.length > 0 && (
        <div className="bg-layout-bg">
          {menus.map((subItem, index) => (
            <MobileMenuSubItems
              key={index}
              item={subItem}
              ConfigProvider={ConfigProvider}
              setMobileMenuOpen={setMobileMenuOpen}
              child={true}
            />
          ))}
        </div>
      )}
    </>
  ) : (
    <a
      href={item.link}
      target={item.target}
      className={classNames(
        item.current ? "" : "",
        "group py-3 px-4 flex items-center text-sm font-bold border-b"
      )}
    >
      {item.icon && (
        <SvgLoader
          svgXML={item.icon}
          className="w-4 h-4 mr-3 fill-current"
        ></SvgLoader>
      )}
      <span>{ConfigProvider.translateItem(item)}</span>
    </a>
  );
}
function Menus({ conf, setMobileMenuOpen }) {
  const user = conf.user;
  const menus = conf.menus.hasOwnProperty("header") ? conf.menus["header"] : [];
  const ConfigProvider = useConfig();

  return menus
    .filter(
      (x) =>
        (x.type === 0) |
        (x.type === 1 && user != null) |
        (x.type === 2 && user === null)
    )
    .filter((x) => x.parent === "000000000000000000000000")
    .map((item, index) => (
      <MobileMenuSubItems
        key={index}
        item={item}
        ConfigProvider={ConfigProvider}
        setMobileMenuOpen={setMobileMenuOpen}
        menus={menus.filter(
          (x) =>
            x.parent === item._id &&
            (x.type === 0) |
              (x.type === 1 && user != null) |
              (x.type === 2 && user === null)
        )}
      />
    ));
}

export default FooterNav;
