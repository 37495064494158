import React, { createContext, useContext, useEffect, useState } from "react";
import Loading from "../components/Common/Loading/Loading";
import Requests from "../helpers/Requests";
const ConfigContext = createContext();

export function ConfigProvider({ children }) {
  const key = "translations";
  const [config, setConfig] = useState(null);
  const [translations, setTranslations] = useState(null);
  const [error, setError] = useState(false);
  let checkUserInterval = null;
  const checkUserIntervalDuration = 60000;
  const checkUser = async () => {
    clearInterval(checkUserInterval);
    const fetchData = async () => {
      const data = await Requests.postData("/user/check", {});
      return data;
    };
    fetchData()
      .then((res) => {
        if (res.data.type === "success") {
          setConfig((prevObject) => {
            prevObject.user = res.data.data;
            return prevObject;
          });
          if (res.data.lan !== config.lan) {
            sessionStorage.removeItem(key);
            window.location.reload();
          }
        } else {
          setConfig((prevObject) => {
            prevObject.user = null;
            return prevObject;
          });
        }
        checkUserInterval = setInterval(() => {
          checkUser();
        }, checkUserIntervalDuration);
      })
      .catch((e) => {
        console.log(e);
        console.log("/error/404/check request error");
      });
  };
  useEffect(() => {
    Requests.getConf()
      .then((response) => {
        setConfig(response.data.data);
        let sessionlan = sessionStorage.getItem(key + "_lan");
        if (
          !(
            sessionlan &&
            sessionlan.length === 2 &&
            sessionlan === response.data.data.lan
          )
        ) {
          refreshTranslations();
        }
      })
      .catch(() => {
        setError(true);
      });

    let found = false;
    const savedData = sessionStorage.getItem(key);
    if (savedData) {
      let data = JSON.parse(savedData);
      if (typeof data === "object" && Object.keys(data).length > 0) {
        found = true;
        setTranslations(data);
      }
    }
    if (!found) {
      refreshTranslations();
    }
    // eslint-disable-next-line
    checkUserInterval = setInterval(() => {
      checkUser();
    }, checkUserIntervalDuration);
    return () => {
      clearInterval(checkUserInterval);
    };
  }, []);
  const refreshTranslations = () => {
    Requests.getTranslations()
      .then((response) => {
        setTranslations(response.data.data);
        sessionStorage.setItem(key, JSON.stringify(response.data.data));
        sessionStorage.setItem(key + "_lan", response.data.lan);
      })
      .catch((err) => {
        setError(true);
      });
  };
  const translateItem = (item) => {
    const lan = config.lan;
    return item.hasOwnProperty("translation") && item.translation
      ? item.translation.hasOwnProperty(lan)
        ? item.translation[lan]
        : item.translation.hasOwnProperty("en")
        ? item.translation["en"]
        : item.hasOwnProperty("name")
        ? item.name
        : ""
      : item.hasOwnProperty("name")
      ? item.name
      : "";
  };
  const getTranslation = (item) => {
    return translations.hasOwnProperty(item) ? translations[item] : item;
  };
  const signout = () => {
    const fetchData = async () => {
      const data = await Requests.postData("/user/logout", {});
      return data;
    };
    fetchData()
      .then((res) => {
        window.location.reload();
      })
      .catch(() => {
        console.log("/error/404/logout error");
      });
  };
  const updateBalance = (balance, strBalance) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      user: {
        ...prevConfig.user,
        balance: balance,
        strBalance: strBalance,
      },
    }));
  };
  const updateJackpotPrize = (jackpotPrize) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      user: {
        ...prevConfig.user,
        jackpotPrize: jackpotPrize,
      },
    }));
  };
  const updateBonusBalance = (_id, balance) => {
    setConfig((prevConfig) => {
      return {
        ...prevConfig,
        user: {
          ...prevConfig.user,
          userSessionBonuses: prevConfig.user.userSessionBonuses.map(
            (prevItem) =>
              prevItem._id === _id
                ? {
                    ...prevItem,
                    balance: balance,
                  }
                : prevItem
          ),
        },
      };
    });
  };
  const updateUnreadMessages = (unreadMessages) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      user: {
        ...prevConfig.user,
        unreadMessages: unreadMessages,
      },
    }));
  };
  const updateUnreadNotifications = (unreadNotifications) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      user: {
        ...prevConfig.user,
        unreadNotifications: unreadNotifications,
      },
    }));
  };
  return (
    <ConfigContext.Provider
      value={{
        config,
        translations,
        translateItem,
        getTranslation,
        checkUser,
        signout,
        updateBalance,
        updateJackpotPrize,
        updateBonusBalance,
        updateUnreadMessages,
        updateUnreadNotifications,
      }}
    >
      {error ? (
        <div>API Connection Error</div>
      ) : config !== null && translations !== null ? (
        children
      ) : (
        <Loading />
      )}
    </ConfigContext.Provider>
  );
}

export function useConfig() {
  return useContext(ConfigContext);
}
