import { React, useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CasinoTopGames from "../components/Casino/CasinoTopGames";
import { useConfig } from "../context/ConfigContext";
// import ItemStars from "../assets/img/home/itemStars.svg";
// import Luckyseven from "../assets/img/home/luckyseven.svg";
// import Dice from "../assets/img/home/dice.png";
// import Wheel from "../assets/img/home/wheel.svg";
// import Diamond from "../assets/img/home/diamond.svg";
// import Megawheel from "../assets/img/home/megawheel.svg";
// import Bubbles from "../assets/img/home/bubbles.svg";
// import Pumpkin from "../assets/img/home/pumpkin.svg";
// import Clock from "../assets/img/home/clock.svg";
// import Gold from "../assets/img/home/gold.svg";
// import Gamepad from "../assets/img/home/gamepad.svg";
import Slider from "../components/Common/Slider";
import { useDeviceSize } from "../context/DeviceSizeContext";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { TrophyIcon } from "@heroicons/react/24/outline";
import Requests from "../helpers/Requests";
import Popup from "../components/Common/Popup";
import { Link } from "react-router-dom";
function Home() {
  const DeviceSizeProvider = useDeviceSize();
  const [sliderPlatform, setSliderPlatform] = useState(
    DeviceSizeProvider.deviceType === "sm" ? "mobile" : "web"
  );
  useEffect(() => {
    const newPlatform =
      DeviceSizeProvider.deviceType === "sm" ? "mobile" : "web";
    if (sliderPlatform !== newPlatform) {
      setSliderPlatform("");
      setTimeout(() => {
        setSliderPlatform(newPlatform);
      }, 500);
    }
    // eslint-disable-next-line
  }, [DeviceSizeProvider.deviceType]);

  return (
    <>
      <div className="w-full flex flex-col px-3 py-5">
        {sliderPlatform !== "" && (
          <Slider
            platform={DeviceSizeProvider.deviceType === "sm" ? "mobile" : "web"}
            location="home"
          />
        )}
      </div>
      {/* <div className="w-full flex flex-col-reverse lg:flex-row">
        <div className="flex flex-col items-center justify-center leading-10 w-full -mt-32 lg:mt-0 lg:px-16 lg:w-1/2">
          <div className="text-xl xl:text-[24px] font-light">
            SIGN UP & <span className="text-home-from-1">GET</span> REWARD!
          </div>
          <div className="text-4xl xl:text-[64px] pt-2 pb-4 font-bold flex items-center gap-2">
            <div>UNTIL</div>
            <div className="bg-gradient-to-r from-home-from-1 to-home-to-1 p-1 lg:p-4">
              <div className="bg-clip-text">20.000$</div>
            </div>
          </div>
          <div className="lg:h-32 xl:h-40">&nbsp;</div>
        </div>
        <div className="w-full lg:w-1/2">
          <img srcSet={Slider} alt="" className="w-full" />
        </div>
      </div> */}
      <div className="mx-auto w-full">
        <div className="p-3">
          {/* <HomeHelpers /> */}
          <TopWinners />
          <CasinoTopGames showSearch={false} />
          <Brands />
        </div>
      </div>
      <HomePopup />
    </>
  );
}

function HomePopup() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const getPopups = () => {
    if (!loading) {
      setLoading(true);
      Requests.postData("/general/getPopups", {})
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
          } else {
            setData(res.data);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    const isPopupShownBefore = sessionStorage.getItem("isPopupShown");
    if (!isPopupShownBefore) {
      getPopups();
      sessionStorage.setItem("isPopupShown", true);
    }
    // eslint-disable-next-line
  }, []);
  return data.map((item, i) => (
    <Popup
      key={i}
      title={item.title}
      content={
        <img
          srcSet={`//demolinker.com/upload/img/${item.image}`}
          className="max-w-[calc(100vw-100px)] max-h-[calc(100vh-250px)] w-full h-full"
          alt={item.title}
        />
      }
    />
  ));
}
// function HomeHelpers() {
//   const sets = [
//     { img: Luckyseven, name: "Slots", link: "/casino/slot" },
//     { img: Gamepad, name: "Virtual Games", link: "/casino/virtualSports" },
//     { img: Dice, name: "Dice", link: "/casino/dice" },
//     { img: Wheel, name: "Wheel", link: "/casino/wheel" },
//     { img: Diamond, name: "Live Games", link: "/casino/liveCasino" },
//     { img: Megawheel, name: "Vegas", link: "/casino/vegas" },
//     { img: Bubbles, name: "Bubbles", link: "/casino/bubbles" },
//     { img: Pumpkin, name: "MoneyTrain", link: "/casino/moneytrain" },
//     { img: Gold, name: "BonusDice", link: "/casino/bonusdice" },
//     { img: Clock, name: "NewGames", link: "/casino/newgames" },
//   ];
//   return (
//     <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
//       <div className="hidden bg-home-from-1 from-home-from-1 to-home-to-1"></div>
//       <div className="hidden bg-home-from-2 from-home-from-2 to-home-to-2"></div>
//       <div className="hidden bg-home-from-3 from-home-from-3 to-home-to-3"></div>
//       <div className="hidden bg-home-from-4 from-home-from-4 to-home-to-4"></div>
//       <div className="hidden bg-home-from-5 from-home-from-5 to-home-to-5"></div>
//       <div className="hidden bg-home-from-6 from-home-from-6 to-home-to-6"></div>
//       <div className="hidden bg-home-from-7 from-home-from-7 to-home-to-7"></div>
//       <div className="hidden bg-home-from-8 from-home-from-8 to-home-to-8"></div>
//       <div className="hidden bg-home-from-9 from-home-from-9 to-home-to-9"></div>
//       <div className="hidden bg-home-from-10 from-home-from-10 to-home-to-10"></div>
//       {sets.map((item, i) => (
//         <Link
//           to={item.link}
//           key={i}
//           className={classNames(
//             "rounded-md cursor-pointer aspect-[4/3] text-white hover:brightness-105 bg-gradient-to-b flex flex-col w-full h-full",
//             "from-home-from-" + (i + 1) + " to-home-to-" + (i + 1) + ""
//           )}
//         >
//           <div className="grow flex items-center justify-center relative ">
//             <div className="w-full flex h-full justify-center items-center z-[1]">
//               <img srcSet={item.img} alt="" className="w-[40%]" />
//             </div>
//             <div className="absolute z-0 w-full h-full flex items-center justify-center">
//               <img srcSet={ItemStars} alt="" className="w-[55%] fill-white" />
//             </div>
//           </div>
//           <div
//             className={classNames(
//               "h-[44px] rounded-b-md text-md flex items-center justify-center",
//               "bg-home-from-" + (i + 1)
//             )}
//           >
//             {item.name}
//           </div>
//         </Link>
//       ))}
//     </div>
//   );
// }
function Brands() {
  const ConfigProvider = useConfig();
  const brands = ConfigProvider.config.brands;
  const sliderRef = useRef(null);
  const scrollAmount = 520;
  return (
    <section className="mt-10 mb-5">
      <div className="flex w-full">
        <h3 className="text-left px-2 font-semibold text-xl my-4 grow">
          {ConfigProvider.getTranslation("Providers")}
        </h3>
        <div className="mx-2">
          <button
            className="ring-0 pl-1.5 pr-2 py-1.5 bg-layout-bg hover:bg-left-title-bg hover:text-left-title-text translation duration-500 text-page-text rounded-full"
            onClick={() => {
              const container = sliderRef.current;
              container.scrollLeft -= scrollAmount; // Scroll right by the specified amount
            }}
          >
            <ChevronLeftIcon className="w-7" />
          </button>
        </div>
        <div>
          <button
            className="ring-0 pl-1.5 pr-2 py-1.5 bg-layout-bg hover:bg-left-title-bg hover:text-left-title-text translation duration-500 text-page-text rounded-full"
            onClick={() => {
              const container = sliderRef.current;
              container.scrollLeft += scrollAmount; // Scroll right by the specified amount
            }}
          >
            <ChevronRightIcon className="w-7" />
          </button>
        </div>
      </div>
      <div
        className="block overflow-x-scroll noscrollbar smoothScroll"
        ref={sliderRef}
      >
        <div className="flex flex-nowrap gap-8 items-center w-0">
          {brands.map((item, index) => (
            <div className="inline-block" key={index}>
              <div className="group bg-layout-bg rounded-lg">
                <div className="flex w-40 h-32 items-center justify-center p-5">
                  <LazyLoadImage
                    src={process.env.REACT_APP_CDN_ENDPOINT + item}
                    className="w-full group-hover:scale-110 transition duration-1000 "
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

function TopWinners() {
  const ConfigProvider = useConfig();
  const sliderRef = useRef(null);
  const scrollAmount = 520;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);
  const getItems = () => {
    const fetchData = async () => {
      const data = await Requests.postData("/casino/getWinners", {
        period: "allTimes",
        type: "topWinners",
        transaction: "casino",
      });
      return data;
    };

    fetchData()
      .then((res) => {
        if (res.data.type === "success") {
          setData(res.data.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const cdnEndpoint = process.env.REACT_APP_CDN_ENDPOINT;
  return loading || data.length > 0 ? (
    <section className="mb-5">
      <div className="flex py-5 px-1 mb-2">
        <div className="grow text-xl md:text-2xl items-center flex space-x-2 font-semibold">
          <div>
            <TrophyIcon className="w-8 h-8 text-menu-bg" />
          </div>
          <div>{ConfigProvider.getTranslation("TopWinners")}</div>
        </div>
        <div className="mx-2">
          <button
            className="ring-0 pl-1.5 pr-2 py-1.5 bg-layout-bg hover:bg-left-title-bg hover:text-left-title-text translation duration-500 text-page-text rounded-full"
            onClick={() => {
              const container = sliderRef.current;
              container.scrollLeft -= scrollAmount; // Scroll right by the specified amount
            }}
          >
            <ChevronLeftIcon className="w-7" />
          </button>
        </div>
        <div>
          <button
            className="ring-0 pl-1.5 pr-2 py-1.5 bg-layout-bg hover:bg-left-title-bg hover:text-left-title-text translation duration-500 text-page-text rounded-full"
            onClick={() => {
              const container = sliderRef.current;
              container.scrollLeft += scrollAmount; // Scroll right by the specified amount
            }}
          >
            <ChevronRightIcon className="w-7" />
          </button>
        </div>
      </div>
      <div
        className="block overflow-x-scroll noscrollbar smoothScroll"
        ref={sliderRef}
      >
        <div className="flex flex-nowrap gap-8 items-center w-0">
          {loading
            ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                <div className="inline-block" key={index}>
                  <div className="w-72 min-h-22 p-2 bg-layout-bg rounded-lg flex gap-2">
                    <div className="w-32 flex items-center">
                      <div className="bg-layout-border animate-pulse w-full aspect-[16/11] rounded">
                        &nbsp;
                      </div>
                    </div>
                    <div className="grow flex items-center min-w-0">
                      <div className="flex flex-col font-bold text-sm w-full">
                        <div className="bg-layout-border animate-pulse w-full rounded mb-1">
                          &nbsp;
                        </div>
                        <div className="bg-layout-border animate-pulse w-full rounded mb-1">
                          &nbsp;
                        </div>
                        <div className="bg-layout-border animate-pulse w-full rounded">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : data.map((item, index) => (
                <Link
                  to={
                    ConfigProvider.config.user !== null
                      ? "/casinoGame/" +
                        item.extra.game +
                        (item.extra.fullScreen === "1" ? "/full" : "")
                      : ""
                  }
                  className={`inline-block ${
                    ConfigProvider.config.user !== null
                      ? "cursor-pointer"
                      : "cursor-default"
                  }`}
                  key={index}
                >
                  <div className="w-72 min-h-22 p-2 bg-layout-bg rounded-lg flex gap-2">
                    <div className="w-32 flex items-center">
                      <LazyLoadImage
                        src={
                          item.extra.imageUrl.startsWith("casino/")
                            ? `${cdnEndpoint}/upload/img/${item.extra.imageUrl}`
                            : item.extra.imageUrl
                        }
                        className="w-full aspect-[16/11] rounded"
                      />
                    </div>
                    <div className="grow flex items-center min-w-0">
                      <div className="flex flex-col font-bold text-sm min-w-0">
                        <div className="truncate">{item.uname}</div>
                        <div className="text-menu-bg truncate">
                          {`${item.amount} ${
                            item.currency === "TRY"
                              ? "₺"
                              : item.currency === "EUR"
                              ? "€"
                              : item.currency
                          }`}
                        </div>
                        <div className="truncate">{item.extra.displayName}</div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
        </div>
      </div>
    </section>
  ) : null;
}

export default Home;
